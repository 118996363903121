import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getCartsCount } from '../../api/cart';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

function CartIcon() {
  const { data } = useQuery(QueryKeyString.CART_COUNT, async () => getCartsCount());

  return (
    <Link to="/cart">
      <div className="cartIconCount">{data?.count ?? 0}</div>
      <FontAwesomeIcon icon={faCartShopping} className="cartIcon" />
    </Link>
  );
}

export default CartIcon;

import { useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import '@solana/wallet-adapter-react-ui/styles.css';
import '../../styles/wallet.css';
import WithDraw from './WithDraw';
import Send from './Send';
import Zebec from './Zebec';

function Wallet() {
  const location = useLocation();
  const navigator = useNavigate();

  useEffect(() => {
    if (location.pathname.match(/dashboard\/wallet$/)) navigator('/dashboard/wallet/send');
  }, [location]);

  return (
    <>
      <div className="select_wallet_container">
        <div className="wallet_navbar">
          <Link to="/dashboard/wallet/send">Send</Link>
          <Link to="/dashboard/wallet/withdraw">Withdraw</Link>
          <Link to="/dashboard/wallet/zebec">Zebec</Link>
        </div>
      </div>
      <Routes>
        <Route path="withdraw" element={<WithDraw />} />
        <Route path="send" element={<Send />} />
        <Route path="zebec" element={<Zebec />} />
      </Routes>
    </>
  );
}

export default Wallet;

import { useQueryClient } from 'react-query';
import { deleteCart } from '../../api/cart';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useModal from '../../hooks/useModal';
import { Logger } from '../../utils/logger.util';
import Alert from '../Alert';
import Button from '../Button';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('DeleteCart');

function DeleteCart({ cartId, productId }: { cartId: string; productId: string }) {
  const queryClient = useQueryClient();
  const { isShowing: showDeleteModal, toggle: toggleDeletePopup } = useModal();

  const { mutate: removeCart, isLoading } = useCustomMutation({
    api: deleteCart,
    success: 'The cart has been deleted succesfully .',
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyString.CART_DATA]).catch((err) => logger.error(err));
    },
    onError: () => {
      queryClient.invalidateQueries([QueryKeyString.CART_DATA]).catch((err) => logger.error(err));
    },
  });

  const onDeleteClick = () => {
    removeCart({ cartId, productId });
    queryClient.invalidateQueries(QueryKeyString.CART_COUNT).catch(logger.error);
  };

  return (
    <>
      <Button bgColor="var(--red)" buttonPadding="20px 0px" onClick={toggleDeletePopup}>
        Remove from Cart
      </Button>
      <Alert
        loading={isLoading}
        onLoadingText="Deleting Cart"
        actionDetail="Are you sure you want to delete this Cart?"
        alertTitle="Delete Cart"
        hideAlert={toggleDeletePopup}
        showAlert={showDeleteModal}
        actionButtonHandler={() => onDeleteClick()}
        actionButtonName="Delete Cart"
        buttonType="red"
        cancelButtonHandler={toggleDeletePopup}
      />
    </>
  );
}

export default DeleteCart;

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Header from './components/Header';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Home from './pages/Home';
import About from './pages/About';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import Wallet from './pages/wallet/Wallet';
import './styles/fontawesome.min.css';
import Dashboard from './pages/Dashboard';
import Products from './pages/Dashboard/Products';
import './styles/toast.css';
import AddProduct from './pages/Dashboard/AddProduct';
import Product from './pages/Product';
import Orders from './pages/Dashboard/Orders';
import RequireAuth from './components/RequireAuth';
import Cart from './pages/Cart';
import { Logger } from './utils/logger.util';
import { useSolana } from './hooks/useSolana';
import ZebecWallet from './pages/Dashboard/Transactions/ZebecWallet';
import Wallets from './pages/Dashboard/Transactions/Wallets';
import TransactionIndex from './pages/Dashboard/Transactions/TransactionIndex';
import useAuth from './hooks/useAuth';
import { QueryKeyString } from './types/enums/query-key-string.enum';

export default function App() {
  const queryClient = useQueryClient();
  const { checkLogin, isAuthenticated } = useAuth();
  const logger = new Logger('App');

  useEffect(() => {
    checkLogin();
    logger.info('App initialized');
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(QueryKeyString.CART_COUNT).catch(logger.error);
  }, [isAuthenticated]);

  const { SolanaProvider } = useSolana();
  return (
    <div className="app">
      <SolanaProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route
                path="products"
                element={
                  <RequireAuth>
                    <Products />
                  </RequireAuth>
                }
              />
              <Route
                path="products/add"
                element={
                  <RequireAuth>
                    <AddProduct />
                  </RequireAuth>
                }
              />
              <Route
                path="orders"
                element={
                  <RequireAuth>
                    <Orders />
                  </RequireAuth>
                }
              />
              <Route
                path="transactions"
                element={
                  <RequireAuth>
                    <TransactionIndex />
                  </RequireAuth>
                }
              />

              <Route
                path="transactions/zebec-wallet"
                element={
                  <RequireAuth>
                    <ZebecWallet />
                  </RequireAuth>
                }
              />
              <Route
                path="transactions/wallets"
                element={
                  <RequireAuth>
                    <Wallets />
                  </RequireAuth>
                }
              />
              <Route path="wallet/*" element={<Wallet />} />
            </Route>
            <Route path="/products/:productId" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
          </Routes>
        </Router>
      </SolanaProvider>
      <Toaster />
    </div>
  );
}

import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { IWalletsRequest } from '../../types/interfaces/api/wallets.interface';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { createWallet, updateWallet } from '../../api/wallets';
import { Logger } from '../../utils/logger.util';
import Modal from '../Modal';
import Button from '../Button';
import InputField from '../InputField';
import useFormInput from '../../hooks/useFormInput';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('AddorPatchWallet');

export default function AddorPatchWallet({
  defaultValues,
  hidePopup,
  showPopup,
  popupTitle,
  actionButtonName,
  cancelButtonHandler,
  addWallet,
  onLoadingText,
  walletId,
}: {
  popupTitle: string;
  hidePopup: () => void;
  showPopup: boolean;
  addWallet: boolean;
  cancelButtonHandler: () => void;
  actionButtonName: string;
  onLoadingText: string;
  walletId?: string;
  defaultValues?: { wallet?: string; name?: string };
}) {
  const { publicKey } = useWallet();
  const { setVisible: setWalletModelVisible } = useWalletModal();
  const linkedWallet = useMemo(() => publicKey?.toBase58(), [publicKey]);

  const queryClient = useQueryClient();
  const {
    name: { value: name, handleChange: onNameChange },
    wallet: { value: wallet, handleChange: onWalletChange, setValue: setWallet },
  } = useFormInput([
    { name: 'name', initialState: defaultValues?.name },
    { name: 'wallet', initialState: defaultValues?.wallet },
  ]);

  const { mutate: patchWalletMutate, isLoading: patchLoading } = useCustomMutation({
    api: updateWallet,
    success: 'Wallet Updated successfully.',
    error: 'Error',
    onSuccess: () => {
      hidePopup();
      queryClient.invalidateQueries([QueryKeyString.WALLET_DATA]).catch((err) => logger.error(err));
    },
  });

  const { mutate: postWalletMutate, isLoading: postLoading } = useCustomMutation({
    api: createWallet,
    success: 'Wallet added successfully.',
    error: 'Error',
    onSuccess: () => {
      hidePopup();
      queryClient.invalidateQueries([QueryKeyString.WALLET_DATA]).catch((err) => logger.error(err));
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const walletDetails: IWalletsRequest = {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      name,
      wallet,
    };
    if (addWallet) {
      postWalletMutate(walletDetails);
      return;
    }
    if (!walletId) throw new Error('No wallet id provided');
    patchWalletMutate({ walletId, walletDetails });
  };

  return (
    <>
      <Modal
        isShowing={showPopup}
        hide={() => {
          hidePopup();
        }}
        title={popupTitle}
      >
        <form className="WalletForm" onSubmit={handleSubmit}>
          <InputField
            inputType="text"
            name="name"
            placeholder="Name"
            onChange={onNameChange}
            width="250px"
            display="inline"
            value={name}
          />
          <InputField
            inputType="text"
            name="wallet"
            placeholder="Address"
            onChange={onWalletChange}
            width="460px"
            display="inline"
            marginLeft="20px"
            value={wallet}
          />
          <button
            type="button"
            className="loadLinkedWallet"
            onClick={() => {
              if (!publicKey) {
                setWalletModelVisible(true);
                return;
              }
              setWallet(linkedWallet);
            }}
          >
            Load linked wallet
          </button>
          <div className="action-button-container">
            <Button
              loading={postLoading || patchLoading}
              onLoadingText={onLoadingText}
              bgColor={'var(--blue)'}
              buttonWidth="410px"
            >
              {actionButtonName}
            </Button>
            <button
              className="ctaBtn"
              style={{
                width: '410px',
                backgroundColor: '#afafaf',
              }}
              onClick={() => {
                cancelButtonHandler();
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

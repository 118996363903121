/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import '../../styles/auth.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import * as toast from '../../utils/toast';
import useFormInput from '../../hooks/useFormInput';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { IRegisterRequest, register as registerUser } from '../../api/auth';

const inputBoxDetails = [
  {
    id: 1,
    inputType: 'text',
    placeholder: 'First Name',
    width: '174px',
    name: 'firstName',
    isRequired: true,
    display: 'inline-block',
  },
  {
    id: 2,
    inputType: 'text',
    placeholder: 'Last Name',
    width: '174px',
    name: 'lastName',
    isRequired: true,
    display: 'inline-block',
    marginLeft: '20px',
  },
  {
    id: 3,
    inputType: 'email',
    placeholder: 'Email Address',
    width: '450px',
    marginTop: '35px',
    name: 'email',
    isRequired: true,
  },
  {
    id: 4,
    inputType: 'password',
    placeholder: 'Password',
    width: '450px',
    marginTop: '35px',
    name: 'password',
    isRequired: true,
  },
  {
    id: 5,
    inputType: 'password',
    placeholder: 'Confirm Password',
    width: '450px',
    marginTop: '35px',
    name: 'confirmPassword',
    isRequired: true,
  },
];

export default function Register() {
  const nav = useNavigate();
  const input = useFormInput([
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'email' },
    { name: 'password' },
    { name: 'confirmPassword' },
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { mutate: register, isLoading } = useCustomMutation<IRegisterRequest, any>({
    api: registerUser,
    success: 'Registered Successfully',
    error: 'Error',
    onSuccess: () => {
      nav('/login');
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      firstName: input.firstName.value,
      lastName: input.lastName.value,
      email: input.email.value,
      password: input.password.value,
      username: input.firstName.value,
    };
    if (input.password.value !== input.confirmPassword.value) {
      toast.error('Passwords do not match');
      return;
    }
    register(data);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Register - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="authWrapper register">
        <div className="authContainer">
          <div className="authFormHead">
            <h1 className="authHeader">Start your journey for free.</h1>
            <p className="authSubHeader">Fill up the form and rentify.</p>
          </div>
          <form onSubmit={handleSubmit}>
            {inputBoxDetails.map((item) => (
              <InputField
                key={item.id}
                {...item}
                value={input[`${item.name}`].value ?? ''}
                onChange={input[`${item.name}`].handleChange}
              />
            ))}
            <Button loading={isLoading} onLoadingText="Signing you up..">
              Sign Up
            </Button>

            <div className="authSubText">
              <p>
                Already have an account?&nbsp;
                <Link to="/login" className="spans">
                  Login!
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getWallets } from '../../api/wallets';
import { IWalletDetails } from '../../types/interfaces/api/wallets.interface';
import * as toast from '../../utils/toast';
import Modal from '../Modal';
import '../../styles/Dashboard/walletSelection.css';
import Button from '../Button';
import stringTruncate from '../../utils/stringTruncate';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { updateOrderStatus, updateOrderWallet } from '../../api/order';
import { Logger } from '../../utils/logger.util';
import { ORDER_STATUS } from '../../types/enums/order-status.enum';
import useModal from '../../hooks/useModal';
import AddorPatchWallet from '../Wallet/AddorPatchWallet';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const WalletSelectionModal = ({
  orderId,
  hide,
  show,
  refetchOperation,
}: {
  refetchOperation: string;
  show: boolean;
  hide: () => void;
  orderId: string;
}) => {
  const [wallets, setWallets] = useState<IWalletDetails[]>([]);
  const queryClient = useQueryClient();
  const logger = new Logger('WalletSelection');

  const { mutate: updateTheOrder, isLoading } = useCustomMutation({
    api: updateOrderStatus,
    success: 'The order has been succesfully accepted.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries([refetchOperation]).catch(logger.error);
    },
  });

  const { data } = useQuery([QueryKeyString.WALLET_DATA], async () => getWallets(), {
    onError: (err: Error) => {
      toast.error(err?.message);
    },
  });

  useEffect(() => {
    if (!data) return;
    setWallets(data.docs);
  }, [data]);

  const { mutate: patchWallet } = useCustomMutation({
    api: updateOrderWallet,
    success: 'The wallet has been succesfully choosen for this order.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries([refetchOperation]).catch((err) => {
        logger.error(err);
      });
    },
  });

  const assignWalletHandler = (id: string, wallet: string, status: string) => {
    patchWallet({
      id,
      renterWallet: wallet,
    });
    updateTheOrder({
      id,
      status,
    });
  };

  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [walletId, setWalletId] = useState<string>('');
  const { isShowing: showAddWalletPopup, toggle: toggleAddWalletPopup } = useModal();

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (walletAddress: string) =>
    selectedItem === walletAddress ? setSelectedItem('') : setSelectedItem(walletAddress);

  return (
    <Modal title="Select a wallet" hide={hide} isShowing={show}>
      <div className="addWalletCont">
        <Button buttonPadding="18px 20px" onClick={toggleAddWalletPopup}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <AddorPatchWallet
          onLoadingText="Adding Wallet"
          popupTitle="Add Wallet"
          hidePopup={toggleAddWalletPopup}
          showPopup={showAddWalletPopup}
          addWallet={true}
          actionButtonName="Add The Wallet"
          cancelButtonHandler={toggleAddWalletPopup}
        />
      </div>
      <div className="wallet-dropdown">
        <div className="wallet-dropdown-header" onClick={toggleDropdown}>
          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (
            stringTruncate(selectedItem || 'Select your wallet', 25)
          ) : (
            <p className="selected-wallet-address">
              {selectedItem ? wallets.find((item) => item.wallet === selectedItem)?.wallet : 'Select your wallet'}
            </p>
          )}
          <p className="selected-wallet-name">
            {selectedItem ? wallets.find((item) => item.wallet === selectedItem)?.name : ''}
          </p>
          <FontAwesomeIcon icon={faAngleDown} className={`arrow-down-icon ${isOpen ? 'open' : ''}`} />
        </div>
        <div className={`wallet-dropdown-body ${isOpen ? 'open' : ''}`}>
          {wallets.map((item, index) => (
            <div
              key={index}
              className="wallet-dropdown-item"
              onClick={() => {
                handleItemClick(item.wallet);
                setOpen(!isOpen);
                setWalletId(item._id);
              }}
              id={item._id}
            >
              {item.wallet}
              <p className="wallet-name">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="action-button-container">
        <Button loading={isLoading} onClick={() => assignWalletHandler(orderId, walletId, ORDER_STATUS.CONFIRMED)}>
          Assign selected wallet and accept the order
        </Button>
      </div>
    </Modal>
  );
};

export default WalletSelectionModal;

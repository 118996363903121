import defaultProductImage from '../images/defaultProductImage.png';
import imageURL from '../utils/imageUrl';

export default function ProductImageDisplay({ productImg }: { productImg: string[] }) {
  const [mainImage, ...subImage] = productImg;
  return (
    <div className="productImageContainer">
      <img
        src={!productImg || productImg.length === 0 ? defaultProductImage : imageURL(mainImage) ?? defaultProductImage}
        className="productImageById mainImage"
        alt="product"
      />
      <div className="subImageContainer">
        {subImage?.map((image, index) => (
          <img src={imageURL(image) ?? defaultProductImage} className="subImage " key={index} alt="product" />
        ))}
      </div>
    </div>
  );
}

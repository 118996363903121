import { useState } from 'react';
import '../styles/Dashboard/notification.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

export default function Notification() {
  const data = [
    { id: 0, message: 'User1 started streaming the payment.' },
    { id: 1, message: 'User1 has sent you an offer for Product2.' },
  ];
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="dropdown">
      <button
        className="dashboardIcon"
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        <FontAwesomeIcon icon={faBell} className="dashboardIcon" />
      </button>
      <div className={`dropdown-body ${isOpen ? 'open' : ''}`}>
        {data.map((item, index) => (
          <div className="dropdown-item" key={index}>
            {item.message}
          </div>
        ))}
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { getOutgoingTransaction } from '../../api/transaction';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';
import { Logger } from '../../utils/logger.util';
import Pagination from '../pagination';
import FilterByDate from '../Products/FilterByDate';
import OutgoingTransactionCard from './OutgoingTransactionCard';

const logger = new Logger('OutgoingTransaction');

function OutgoingTransaction() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortByDate, setSortByDate] = useState<string>();
  const [sortType, setSortType] = useState('Descending');

  const { refetch, data } = useQuery(
    [QueryKeyString.OUTGOING_TRANSACTION_DATA, currentPage, sortByDate],
    async () => getOutgoingTransaction({ page: currentPage, sort: sortByDate }),
    {
      onSuccess: (res) => {
        setTotalDocs(res.totalDocs);
        setTotalPages(res.totalPages);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );

  useEffect(() => {
    refetch().catch(logger.error);
  }, [currentPage, sortByDate]);
  return (
    <>
      <div className="pageFunctions">
        <div className="pageFunction">
          <FilterByDate
            onOrderClick={(e) => {
              const elem: HTMLElement = e.currentTarget;
              if (elem.dataset.value === 'createdAt:asc') setSortType('Ascending');
              if (elem.dataset.value === 'createdAt:desc') setSortType('Descending');
              setSortByDate(elem.dataset.value);
            }}
            sortType={sortType}
          />
        </div>
      </div>
      <div className="cartCardWrapper">
        {data?.docs?.map((details) => (
          <OutgoingTransactionCard key={details._id} {...details} />
        ))}
      </div>

      <div className="paginationContainer">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalDocs}
          pageSize={9}
          totalPages={totalPages}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
}

export default OutgoingTransaction;

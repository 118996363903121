import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import ICreateOrder, { IOrderDetail, IOrdersResponse } from '../types/interfaces/api/order.interface';
import createApi from '../utils/axios';

const orderApi = createApi('/orders');

export const createOrder = async (carts: ICreateOrder): Promise<IOrderDetail> => {
  const { data } = (await orderApi({
    data: carts,
    headers: {
      'Content-Type': 'application/json',
    },
    url: '/',
    method: METHODS.POST,
  })) as AxiosResponse<IOrderDetail>;
  return data;
};

export const getRenteeOrders = async ({ page = 1, q = '', status = '' }): Promise<IOrdersResponse> => {
  const { data } = (await orderApi({
    url: '/rentee',
    method: METHODS.GET,
    params: {
      page,
      q,
      status,
    },
  })) as AxiosResponse<IOrdersResponse>;
  return data;
};

export const getRenterOrders = async ({ page = 1, q = '', status = '' }): Promise<IOrdersResponse> => {
  const { data } = (await orderApi({
    url: '/renter',
    method: METHODS.GET,
    params: {
      page,
      q,
      status,
    },
  })) as AxiosResponse<IOrdersResponse>;
  return data;
};

export const getOrderById = async (orderId: string): Promise<IOrderDetail> => {
  const { data } = (await orderApi({
    url: `/${orderId}`,
    method: METHODS.GET,
  })) as AxiosResponse<IOrderDetail>;
  return data;
};

export const updateOrderStatus = async (param: { id: string; status: string }): Promise<IOrderDetail> => {
  const { data } = (await orderApi({
    data: {
      status: param.status,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    url: `/${param.id}`,
    method: METHODS.PATCH,
  })) as AxiosResponse<IOrderDetail>;
  return data;
};

export const updateOrderWallet = async (param: { id: string; renterWallet: string }): Promise<IOrderDetail> => {
  const { data } = (await orderApi({
    data: {
      renterWallet: param.renterWallet,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    url: `/${param.id}`,
    method: METHODS.PATCH,
  })) as AxiosResponse<IOrderDetail>;
  return data;
};

import { useEffect, useState } from 'react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWallet } from '@solana/wallet-adapter-react';
import { QueryClient } from 'react-query';
import { useZebec } from '../../hooks/useSolana';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';
import { TRANSACTION_STATUS } from '../../types/enums/transaction-status.enum';
import { IIncomingTransaction } from '../../types/interfaces/api/transaction.interface';
import { Logger } from '../../utils/logger.util';
import Button from '../Button';
import * as toast from '../../utils/toast';

const logger = new Logger('Outgoing_Transaction');
const query = new QueryClient();

function OutgoingTransactionCard(details: IIncomingTransaction) {
  const { publicKey } = useWallet();
  const { streamPause, pauseStreamLoading, streamResume, resumeStreamLoading, streamStop, stopStreamLoading } = useZebec();
  const [isResumed, setIsResumed] = useState(true);
  const [noOperationStatus, setNoOperationStatus] = useState(true);
  useEffect(() => {
    setIsResumed(details.status !== TRANSACTION_STATUS.PAUSED);
    setNoOperationStatus(
      [TRANSACTION_STATUS.STOPPED, TRANSACTION_STATUS.CANCELLED, TRANSACTION_STATUS.COMPLETED].includes(details.status) ||
        new Date(details.endTime).getTime() < Date.now() ||
        new Date(details.startTime).getTime() > Date.now()
    );
  }, [details]);

  return (
    <>
      <div className="incomingTransactionContainer">
        <div className="incomingTransactionItem">
          <div className="incomingTransactionItemInfo">
            <p className="incomingTransactionItemName">{details.order.cart.product.name}</p>
            <p className="incomingTransactionItemDescription">#{details.order._id}</p>
          </div>
          <div className="incomingTransactionOrderInfo">
            <div className="incomingTransactionItemQuantity">
              <p className="incomingTransactionItemQuantityTitle">Rentee</p>
              <p className="incomingTransactionItemQuantityTitle">{`${details.order?.rentee?.firstName} ${details.order?.rentee?.lastName}`}</p>
            </div>
            <div className="incomingTransactionItemPrice">
              <p className="incomingTransactionItemPriceTitle">Total Price</p>
              <p className="incomingTransactionItemPriceText">{details.amount} SOL</p>
            </div>
            <div className="incomingTransactionItemDate">
              <p className="incomingTransactionItemDateTitle">Start Date</p>
              <p className="incomingTransactionItemDateText">{new Date(details.startTime).toLocaleString()}</p>
            </div>
            <div className="incomingTransactionItemDate">
              <p className="incomingTransactionItemDateTitle">End Date</p>
              <p className="incomingTransactionItemDateText">{new Date(details.endTime).toLocaleString()}</p>
            </div>
          </div>
          <div className="incomingTransactionButtonContainer">
            {isResumed ? (
              <Button
                onClick={() => {
                  if (publicKey?.toBase58() !== details.sender) {
                    toast.error(`Connected wallet is different from ${details.sender}`);
                    return;
                  }
                  streamPause({
                    pda: details.pda,
                    receiver: details.receiver,
                    transactionId: details._id,
                  })
                    .then(() => setIsResumed(false))
                    .catch(logger.error);
                }}
                loading={pauseStreamLoading}
                onLoadingText="Pause Transaction being executed"
                disabled={!details.pda || !details.receiver || noOperationStatus}
              >
                <FontAwesomeIcon icon={faPause} className="Icon" />
                Pause
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (publicKey?.toBase58() !== details.sender) {
                    toast.error(`Connected wallet is different from ${details.sender}`);
                    return;
                  }
                  streamResume({
                    pda: details.pda,
                    receiver: details.receiver,
                    transactionId: details._id,
                  })
                    .then(() => setIsResumed(true))
                    .catch(logger.error);
                }}
                loading={resumeStreamLoading}
                onLoadingText="Resume Transaction being executed"
                disabled={!details.pda || !details.receiver || noOperationStatus}
              >
                <FontAwesomeIcon icon={faPlay} className="Icon" />
                Resume
              </Button>
            )}

            <Button
              bgColor="var(--red)"
              onClick={() => {
                if (publicKey?.toBase58() !== details.sender) {
                  toast.error(`Connected wallet is different from ${details.sender}`);
                  return;
                }
                streamStop({
                  pda: details.pda,
                  receiver: details.receiver,
                  transactionId: details._id,
                })
                  .then(() => query.invalidateQueries(QueryKeyString.OUTGOING_TRANSACTION_DATA).catch(logger.error))
                  .catch(logger.error);
              }}
              loading={stopStreamLoading}
              onLoadingText="Stream Stop Transaction being executed"
              disabled={!details.pda || !details.receiver || noOperationStatus}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutgoingTransactionCard;

import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PostProduct from '../../components/Products/PostProduct';
import '../../styles/Dashboard/products.css';

export default function AddProduct() {
  const [imageIds, setImageIds] = useState<string[]>([]);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Add Product - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className={`content ${imageIds.length === 0 ? `productsCenter` : ''}`}>
        <div className="sectionCont">
          <h1 className="secHead">Add a product</h1>
          <p className="secDesc">Fill in the form shown below to add the product.</p>
          <PostProduct imageState={[imageIds, setImageIds]} />
        </div>
      </div>
    </>
  );
}

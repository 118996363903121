import { AxiosError, AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import { ICartCountResponse, ICartDetail, ICartsResponse, ICreateCart } from '../types/interfaces/api/cart.interface';
import createApi from '../utils/axios';
import { getLocalStorage, setLocalStorage } from '../utils/storage';
import { productById } from './products';

export const CART_LOCALSTORAGE = 'carts';
const cartApi = createApi('/carts');

const getLocalCarts = (): ICreateCart[] => {
  const carts = getLocalStorage(CART_LOCALSTORAGE) || '[]';
  try {
    const localCarts = JSON.parse(carts) as ICreateCart[];
    return localCarts;
  } catch (error) {
    return [];
  }
};

const getLocalCartsPaginated = async ({ page = 1, limit = 10 }): Promise<ICartsResponse> => {
  let populatedCarts: ICartDetail[] = [];
  const localCarts: ICreateCart[] = getLocalCarts();

  if (localCarts.length > 0) {
    const selectedCarts = localCarts.slice((page - 1) * limit, page * limit);
    populatedCarts = await Promise.all(
      selectedCarts.map(async (cart) => {
        const product = await productById(cart.product);
        return { ...cart, product };
      }) as unknown as ICartDetail[]
    );
  }

  return {
    docs: populatedCarts,
    totalDocs: populatedCarts.length,
    limit: 10,
    totalPages: 0,
    page,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  };
};

const localCartCount = (): ICartCountResponse => ({
  count: getLocalCarts().length,
});

const addToLocalCart = (data: ICreateCart) => {
  const carts = getLocalCarts();
  const cartIndex = carts.findIndex((cart) => cart.product === data.product);

  if (cartIndex < 0) carts.push(data);
  else carts[cartIndex].quantity = data.quantity;

  setLocalStorage(CART_LOCALSTORAGE, JSON.stringify(carts));
};

const deleteLocalCart = (productId: string) => {
  const carts = getLocalCarts();
  const postDeleteCarts = carts.filter((item) => item.product !== productId);
  setLocalStorage(CART_LOCALSTORAGE, JSON.stringify(postDeleteCarts));
};

export const createBulkCart = async (bulkCart: ICreateCart[]): Promise<ICartDetail[]> => {
  const { data } = (await cartApi({
    data: bulkCart,
    url: '/bulk',
    method: METHODS.POST,
  })) as AxiosResponse<ICartDetail[]>;
  return data;
};

export const createCart = async ({ product, quantity }: ICreateCart): Promise<ICartDetail> => {
  try {
    const { data } = (await cartApi({
      data: { product, quantity },
      url: '/',
      method: METHODS.POST,
    })) as AxiosResponse<ICartDetail>;
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.status === 401) {
      addToLocalCart({ product, quantity });
    }
    throw error;
  }
};

export const getCartsCount = async (): Promise<ICartCountResponse> => {
  try {
    const { data } = (await cartApi({
      url: '/count',
      method: METHODS.GET,
    })) as AxiosResponse<ICartCountResponse>;
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.status === 401) {
      return localCartCount();
    }
    throw error;
  }
};

export const deleteCart = async (param: { cartId: string; productId: string }) => {
  try {
    await cartApi({
      method: METHODS.DELETE,
      url: `/${param.cartId}`,
    });
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.status === 401) {
      deleteLocalCart(param.productId);
    }
    throw error;
  }
};

export const getCarts = async (pageParams?: { page?: number; limit?: number; sort?: string }): Promise<ICartsResponse> => {
  try {
    const { data } = (await cartApi({
      method: METHODS.GET,
      url: '/',
      params: {
        limit: pageParams?.limit ? pageParams.limit : 9,
        page: pageParams?.page ? pageParams.page : 1,
        sort: pageParams?.sort ? pageParams.sort : '',
      },
    })) as AxiosResponse<ICartsResponse>;
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.status === 401) {
      return getLocalCartsPaginated({ page: pageParams?.page, limit: pageParams?.limit });
    }

    throw error;
  }
};

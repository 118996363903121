import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { updateOrderStatus } from '../../api/order';
import { ORDER_STATUS } from '../../types/enums/order-status.enum';
import useModal from '../../hooks/useModal';
import Alert from '../Alert';
import { Logger } from '../../utils/logger.util';
import StartStreamModal from './StartStreamModal';
import WalletSelectionModal from './WalletSelectionModal';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

export default function ActionDropdown({
  orderId,
  pageName,
  status,
  refetchOperation,
}: {
  status: string;
  pageName: string;
  orderId: string;
  refetchOperation: QueryKeyString;
}) {
  const queryClient = useQueryClient();
  const logger = new Logger('ActionDropdown');
  const { isShowing: startPaymentModal, toggle: togglePaymentModal } = useModal();
  const { isShowing: showDeclineModal, toggle: toggleDeclinePopup } = useModal();
  const { isShowing: walletSelectionModal, toggle: toggleWalletSelectionModal } = useModal();

  const { mutate: declineOrder, isLoading: declineIsLoading } = useCustomMutation({
    api: updateOrderStatus,
    success: 'The order has been succesfully declined.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries([refetchOperation]).catch((err) => {
        logger.error(err);
      });
    },
  });

  const onDeclineHandler = (id: string, orderStatus: string) => {
    declineOrder({
      id,
      status: orderStatus,
    });
  };

  const [isOpen, setOpen] = useState(false);
  const data = [
    {
      id: 0,
      message: (
        <>
          {pageName === 'SentOrders' ? (
            <button
              className="acceptOrderButton"
              onClick={() => {
                if (status === 'CONFIRMED') {
                  togglePaymentModal();
                }
              }}
            >
              <p>Accept Order</p>
            </button>
          ) : (
            <button className="acceptOrderButton" onClick={toggleWalletSelectionModal}>
              <p>Accept Order</p>
            </button>
          )}
        </>
      ),
    },
    {
      id: 1,
      message: (
        <button className="declineOrderButton">
          <p onClick={toggleDeclinePopup}>Decline Order</p>
        </button>
      ),
    },
  ];

  return (
    <>
      <StartStreamModal
        refetchOperation={refetchOperation}
        hide={togglePaymentModal}
        show={startPaymentModal}
        orderId={orderId}
      />
      <WalletSelectionModal
        refetchOperation={refetchOperation}
        hide={toggleWalletSelectionModal}
        show={walletSelectionModal}
        orderId={orderId}
      />
      <div
        className="actionDropdown"
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        <button className="dashboardIcon">
          <FontAwesomeIcon icon={faEllipsisVertical} className="dashboardIcon" />
        </button>
        <div className={`actionDropdown-body ${isOpen ? 'open' : ''}`}>
          {data.map((item, index) => (
            <div className="actionDropdown-item" key={index}>
              {item.message}
            </div>
          ))}
        </div>
        <Alert
          loading={declineIsLoading}
          onLoadingText="Declining the order"
          actionDetail="Are you sure you want to decline this order?"
          alertTitle="Decline Order"
          hideAlert={toggleDeclinePopup}
          showAlert={showDeclineModal}
          actionButtonHandler={() => onDeclineHandler(orderId, ORDER_STATUS.CANCELLED)}
          actionButtonName="Decline Order"
          buttonType="red"
          cancelButtonHandler={toggleDeclinePopup}
        />
      </div>
    </>
  );
}

import axios, { AxiosResponse } from 'axios';

interface currencyResponse {
  solana: {
    usd: number;
  };
}

export async function getCurrentSolPrice() {
  const {
    data: {
      solana: { usd },
    },
  }: AxiosResponse<currencyResponse> = await axios.get(
    'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
  );
  return usd;
}

export async function solToUsd(sol: number) {
  const currentSolPrice = await getCurrentSolPrice();
  return Math.floor(currentSolPrice * sol * 100) / 100;
}
export async function usdToSol(usd: number) {
  const currentSolPrice = await getCurrentSolPrice();
  return Math.floor((usd / currentSolPrice) * 100) / 100;
}

export enum QueryKeyString {
  CART_COUNT = 'cart_count',
  CART_DATA = 'cart_data',
  INCOMING_TRANSACTION_DATA = 'incoming_transaction_data',
  OUTGOING_TRANSACTION_DATA = 'outgoing_transaction_data',
  PRODUCT_DATA = 'product_data',
  IMAGE_DATA = 'image_data',
  RECIEVED_ORDERS = 'recieved_orders_list',
  SENT_ORDERS = 'sent_orders_list',
  WALLET_DATA = 'personal_wallet_list',
}

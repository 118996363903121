import '../../styles/Dashboard/orders.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface ISearchProduct {
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterText: string;
}

export default function SearchProduct({ onFilter, filterText }: ISearchProduct) {
  return (
    <div className="searchBoxContainer">
      <input
        className="productSearch"
        id="search"
        type="text"
        placeholder="Search Product"
        value={filterText}
        onChange={onFilter}
      />
      <FontAwesomeIcon icon={faSearch} className="inputIcon" />
    </div>
  );
}

import { useState } from 'react';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { Logger } from '../../utils/logger.util';
import { useZebec } from '../../hooks/useSolana';

const logger = new Logger('WithDraw_Zebec');

function WithDraw() {
  const [sender, setSender] = useState('');
  const [pda, setPda] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [transactionId] = useState<string>('630663951eee68f29227241e');
  const { streamWithdraw } = useZebec();

  return (
    <>
      <InputField placeholder="pda" value={pda} onChange={(e) => setPda(e.target.value)} width="450px" />

      <InputField
        placeholder="Withdraw Amount"
        value={withdrawAmount}
        onChange={(e) => setWithdrawAmount(+e.target.value)}
        width="600px"
        inputType="number"
        step="0.01"
      />
      <InputField placeholder="Sender" value={sender} onChange={(e) => setSender(e.target.value)} width="450px" />
      <Button
        onClick={() => {
          streamWithdraw({
            pda,
            sender,
            amount: withdrawAmount,
            transactionId,
          }).catch(logger.error);
        }}
        disabled={!pda || !sender || !withdrawAmount}
      >
        Withdraw money
      </Button>
    </>
  );
}
export default WithDraw;

import '../../styles/auth.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useContext, useEffect } from 'react';
import { AxiosError } from 'axios';
import { ILoginRequest, login as loginUser } from '../../api/auth';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import useFormInput from '../../hooks/useFormInput';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { ILoginResponse } from '../../types/interfaces/api/auth.interface';
import { LocationWithNav } from '../../types/interfaces';
import * as toast from '../../utils/toast';
import useAuth from '../../hooks/useAuth';

type inputBoxDetail = {
  id: number;
  inputType: string;
  width: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  display?: string;
  marginTop?: string;
};

const inputBoxDetails: Array<inputBoxDetail> = [
  {
    id: 1,
    inputType: 'email',
    placeholder: 'Email Address',
    width: '450px',
    name: 'email',
    isRequired: true,
  },
  {
    id: 2,
    inputType: 'password',
    placeholder: 'Password',
    width: '450px',
    marginTop: '35px',
    name: 'password',
    isRequired: true,
  },
];

export default function Login() {
  const nav = useNavigate();
  const { state } = useLocation() as LocationWithNav;
  const input = useFormInput([{ name: 'email' }, { name: 'password' }]);

  const { login: loginDispatch, isAuthenticated } = useAuth();
  const { mutate: login, isLoading } = useCustomMutation<ILoginRequest, ILoginResponse>({
    api: loginUser,
    onSuccess: (data) => {
      loginDispatch(data);
      nav(state?.path || '/');
    },
    onError: (err) => {
      if (err instanceof AxiosError && err.response?.status === 401) {
        toast.success('Credentials did not match.');
        return;
      }
      toast.error('Error');
    },
  });
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      username: input.email.value,
      password: input.password.value,
    };
    login(data);
  };

  useEffect(() => {
    if (isAuthenticated) {
      nav(state?.path || '/');
    }
  }, [isAuthenticated]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Login - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="authWrapper">
        <div className="authContainer">
          <div className="authFormHead">
            <h1 className="authHeader">Login to continue.</h1>
            <p className="authSubHeader">A step away from getting access to the platform.</p>
          </div>
          <form onSubmit={handleSubmit}>
            {inputBoxDetails.map((item) => (
              <InputField
                key={item.id}
                {...item}
                value={input[`${item.name}`].value ?? ''}
                onChange={input[`${item.name}`].handleChange}
              />
            ))}
            <Link className="forgotPassword" to="/forgot-password">
              Forgot your password?
            </Link>
            <Button loading={isLoading} onLoadingText="Logging in..">
              Login
            </Button>
            <div className="authSubText">
              <p>
                Don&#8217;t have an account?&nbsp;
                <Link to="/register" className="spans">
                  Sign Up!
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

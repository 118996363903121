import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery } from 'react-query';
import ProductCard from '../ProductCard';
import { getProducts } from '../../api/products';

function InfiniteProducts() {
  const fetchPosts = async ({ pageParam = 1 }) => {
    const data = await getProducts({ page: pageParam });

    return { results: data.docs, nextPage: data.nextPage, totalPages: data.totalPages };
  };
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery('posts', fetchPosts, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  return (
    <div className="sectionCont">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => (page: number) => fetchNextPage({ pageParam: page })}>
          <div className="productCards">
            {data?.pages.map((page) => page?.results.map((post) => <ProductCard key={post._id} {...post} />))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default InfiniteProducts;

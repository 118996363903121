import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRenterOrders } from '../../api/order';
import { IOrderDetail, IOrderDetailColumn } from '../../types/interfaces/api/order.interface';
import * as toast from '../../utils/toast';
import SearchOrder from '../../components/Orders/SearchOrder';
import '../../index.css';
import ActionDropdown from '../../components/Orders/ActionDropdown';
import FilterByStatus from '../../components/Orders/FilterByStatus';
import { ORDER_STATUS } from '../../types/enums/order-status.enum';
import { Logger } from '../../utils/logger.util';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('ReceivedOrders');

const ReceivedOrders = () => {
  const [orders, setOrders] = useState<IOrderDetail[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [filterByStatus, setFilterByStatus] = useState<string>();
  const [page, setPage] = useState(1);
  const [filterType, setFilterType] = useState('NONE');

  const { refetch } = useQuery(
    [QueryKeyString.RECIEVED_ORDERS, page, filterText, filterByStatus],
    async () => getRenterOrders({ page, q: filterText, status: filterByStatus }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setOrders(data.docs);
        setTotalRows(data.totalDocs);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );

  useEffect(() => {
    refetch().catch(logger.error);
  }, [page, filterText, filterByStatus]);

  const columns: IOrderDetailColumn[] = [
    {
      name: 'Order Id',
      selector: (row) => row._id,
    },
    {
      name: 'Product',
      selector: (row) => row.cart.product?.name,
      sortable: true,
    },
    {
      name: 'Sent By',
      selector: (row) => row.rentee.firstName,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.cart.quantity,
      sortable: true,
    },
    {
      name: 'Total Price (in USD)',
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'Ordered At',
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (item) => (
        <>
          {item.status === 'PENDING' ? (
            <>
              <ActionDropdown
                refetchOperation={QueryKeyString.RECIEVED_ORDERS}
                pageName="ReceivedOrders"
                status={item.status}
                orderId={item._id}
              />
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
  ];

  const customStyle = {
    head: {
      style: { fontSize: '1rem', fontWeight: 500 },
    },
    rows: {
      style: { fontSize: '0.95rem', minHeight: '56px' },
    },
  };

  if (!orders) return null;
  return (
    <>
      <div className="tableFunctions">
        <div className="tableFunction">
          <SearchOrder
            onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
            filterText={filterText}
          />
        </div>
        <div className="tableFunction">
          <FilterByStatus
            filterType={filterType}
            onStatusClick={(e) => {
              Object.values(ORDER_STATUS).map((item) => {
                if (e.currentTarget.dataset.value === item) setFilterType(item);
                return null;
              });
              setFilterByStatus(e.currentTarget.dataset.value);
            }}
          />
        </div>
      </div>
      <div className="tableContainer">
        <DataTable
          highlightOnHover
          pointerOnHover
          columns={columns}
          customStyles={customStyle}
          data={orders}
          subHeader
          persistTableHead
          paginationComponentOptions={{ noRowsPerPage: true }}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={setPage}
        />
      </div>
    </>
  );
};

export default ReceivedOrders;

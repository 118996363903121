import { useQuery } from 'react-query';
import { useWallet } from '@solana/wallet-adapter-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TransactionLayout from '../../../components/Transactions/TransactionsLayout';
import { getWallets } from '../../../api/wallets';
import WalletCard from '../../../components/Wallet/WalletCard';
import AddWallet from '../../../components/Wallet/AddorPatchWallet';
import useModal from '../../../hooks/useModal';
import Button from '../../../components/Button';
import * as toast from '../../../utils/toast';

import '../../../styles/Dashboard/wallets.css';
import WalletNotConnected from '../../../components/WalletNotConnected';

function Wallets() {
  const { publicKey } = useWallet();
  const { isShowing: showAddWalletPopup, toggle: toggleAddWalletPopup } = useModal();

  const { data } = useQuery('walletsData', async () => getWallets(), {
    onError: (err: Error) => {
      toast.error(err?.message);
    },
  });

  return (
    <TransactionLayout
      heading="Access your Zebec Wallet."
      description="Deposit or withdraw money to or from your Zebec Wallet."
    >
      {publicKey ? (
        <>
          <div className="walletsTitle">
            <div className="name">NAME</div>
            <div className="address">WALLET ADDRESS</div>
            <div className="actions">ACTIONS</div>
            <div className="addWalletCont">
              <Button buttonPadding="18px 20px" onClick={toggleAddWalletPopup}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <AddWallet
                onLoadingText="Adding Wallet"
                popupTitle="Add Wallet"
                hidePopup={toggleAddWalletPopup}
                showPopup={showAddWalletPopup}
                addWallet={true}
                actionButtonName="Add The Wallet"
                cancelButtonHandler={toggleAddWalletPopup}
              />
            </div>
          </div>
          <div className="walletsDescription">
            {data?.docs.map((wallet) => (
              <WalletCard key={wallet._id} {...wallet} />
            ))}
          </div>
          {data?.docs.length === 0 ? (
            <div className="emptyWalletCard">
              <div className="noWalletTitle">There are no wallets yet.</div>
              <div className="noWalletDescription">Please add a wallet to start using this page.</div>
            </div>
          ) : null}
        </>
      ) : (
        <WalletNotConnected />
      )}
    </TransactionLayout>
  );
}

export default Wallets;

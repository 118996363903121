import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/logo.png';
import '../styles/header.css';
import CartIcon from './Cart/CartIcon';
import * as toast from '../utils/toast';
import useAuth from '../hooks/useAuth';

export default function Header() {
  const location = useLocation();
  const [headerVisibility, setHeaderVisibility] = useState('block');
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    if (location.pathname.match(/^\/dashboard/)) setHeaderVisibility('none');
    else setHeaderVisibility('');
  }, [location]);
  return (
    <div className="headerWrapper smallDeviceWrapper" style={{ display: headerVisibility }}>
      <div className="leftSide">
        <Link to="/about">
          <p>About</p>
        </Link>
      </div>
      <div className="logoContainer">
        <Link to="/">
          <img src={logo} alt="Rentstore Logo" />
        </Link>
      </div>
      <div className="rightSide">
        {isAuthenticated ? (
          <>
            <Link to="/dashboard">
              <p>Dashboard</p>
            </Link>
            <CartIcon />
            <Link
              onClick={() => {
                logout();
                toast.success('Logged out succefsully.');
              }}
              to="/"
            >
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="dashboardIcon" />
            </Link>
          </>
        ) : (
          <>
            <CartIcon />
            <Link to="/login">
              <p className="loginLink">Login</p>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

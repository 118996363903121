import Modal from './Modal';
import '../styles/alerts.css';
import Button from './Button';

const Alert = ({
  hideAlert,
  showAlert,
  alertTitle,
  actionDetail,
  actionButtonHandler,
  actionButtonName,
  buttonType,
  cancelButtonHandler,
  loading,
  onLoadingText,
}: {
  actionDetail: string;
  alertTitle: string;
  hideAlert: () => void;
  showAlert: boolean;
  actionButtonHandler: () => void;
  cancelButtonHandler: () => void;
  actionButtonName: string;
  buttonType?: string;
  loading: boolean;
  onLoadingText: string;
}) => (
  <>
    <Modal
      isShowing={showAlert}
      hide={() => {
        hideAlert();
      }}
      title={alertTitle}
    >
      <p className="action-detail">{actionDetail}</p>
      <div className="action-button-container">
        <Button
          loading={loading}
          onLoadingText={onLoadingText}
          bgColor={` ${buttonType === 'red' ? '#F54949' : '#13be13'}`}
          onClick={actionButtonHandler}
        >
          {actionButtonName}
        </Button>
        <Button bgColor="#afafaf" onClick={cancelButtonHandler}>
          Cancel
        </Button>
      </div>
    </Modal>
  </>
);

export default Alert;

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet } from 'react-router-dom';
import '../../styles/Dashboard/products.css';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { getOwnProducts } from '../../api/products';
import * as toast from '../../utils/toast';
import Button from '../../components/Button';
import defaultProductImage from '../../images/defaultProductImage.png';
import { IProductDetail } from '../../types/interfaces/api/product.interface';
import DeleteProduct from '../../components/Products/DeleteProduct';
import Pagination from '../../components/pagination';
import SearchProduct from '../../components/Products/SearchProduct';
import FilterByDate from '../../components/Products/FilterByDate';
import imageURL from '../../utils/imageUrl';
import { Logger } from '../../utils/logger.util';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('DashboardProducts');

export default function Products() {
  const [products, setProducts] = useState<IProductDetail[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [sortByDate, setSortByDate] = useState<string>();

  const [sortType, setSortType] = useState('Descending');

  const { refetch } = useQuery(
    [QueryKeyString.PRODUCT_DATA, currentPage, filterText, sortByDate],
    async () =>
      getOwnProducts({ page: currentPage, q: filterText, sort: sortByDate }).catch((err: string) => {
        toast.error(err);
      }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setProducts(data.docs);
        setTotalDocs(data.totalDocs);
        setTotalPages(data.totalPages);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );

  const DProductCard = (product: IProductDetail) => (
    <div className="dashboardProductCard">
      <Link target="_blank" to={`/products/${product._id}`}>
        <div className="productImg">
          <img
            src={
              !product.medias || product.medias.length === 0
                ? defaultProductImage
                : imageURL(product.medias[0]) ?? defaultProductImage
            }
          />
        </div>
      </Link>
      <div className="productCardDetails">
        <Link target="_blank" className="productCardLink" to={`/products/${product._id}`}>
          <p className="productCardName">{product.name}</p>
        </Link>
        <p className="productCardPrice">
          Price <span>${product.rentPrice}</span>
        </p>
        <p className="productCardQuantity">
          Quantity <span>{product.quantity}</span>
        </p>
        <p className="productCardDelivery">
          Delivery <span>{product.delivery ? <span style={{ color: 'var(--blue)' }}>Yes</span> : 'No'}</span>
        </p>
        <p className="productCardDate">
          Added At <span>{new Date(product.createdAt).toLocaleString([], { dateStyle: 'medium' })}</span>
        </p>
        <div className="productFunctions">
          <DeleteProduct productData={product._id} />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    refetch().catch(logger.error);
  }, [currentPage, sortByDate]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Products - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div>
        <div className="content">
          <div className="sectionCont">
            <div className="secHeadCont">
              <h1 className="secHead">Products</h1>
              <p className="secDesc">Add a new product or delete an existing one.</p>
              <div className="addProductCont">
                <Link to="add">
                  <Button buttonPadding="18px 20px">
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="pageFunctions">
              <div className="pageFunction">
                <SearchProduct
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
              </div>
              <div className="pageFunction">
                <FilterByDate
                  onOrderClick={(e) => {
                    if (e.currentTarget.dataset.value === 'createdAt:asc') setSortType('Ascending');
                    if (e.currentTarget.dataset.value === 'createdAt:desc') setSortType('Descending');
                    setSortByDate(e.currentTarget.dataset.value);
                  }}
                  sortType={sortType}
                />
              </div>
            </div>
            <div className="productCardWrapper">
              {products.map((product) => (
                <DProductCard {...product} key={product._id} />
              ))}
            </div>
            <div className="paginationContainer">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalDocs}
                pageSize={9}
                totalPages={totalPages}
                onPageChange={(page) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

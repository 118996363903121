import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { createOrder } from '../../api/order';
import useAuth from '../../hooks/useAuth';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { Logger } from '../../utils/logger.util';
import Button from '../Button';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('OrderCart');

function OrderCart({ cartId }: { cartId: string }) {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const nav = useNavigate();

  const { mutate: createOrderMutate, isLoading: orderLoading } = useCustomMutation({
    api: createOrder,
    success: 'Order Created',
    error: 'Error',
  });

  const onRentClick = () => {
    if (!isAuthenticated) {
      nav('/login', { state: { path: location.pathname } });
      return;
    }
    createOrderMutate({
      carts: [cartId],
    });

    queryClient.invalidateQueries(QueryKeyString.CART_COUNT).catch(logger.error);
  };

  return (
    <Button buttonPadding="20px 0px" onClick={onRentClick} loading={orderLoading}>
      Rent Now
    </Button>
  );
}

export default OrderCart;

import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function About() {
  return (
    <HelmetProvider>
      <Helmet>
        <title> About - Rent Store</title>
      </Helmet>
    </HelmetProvider>
  );
}

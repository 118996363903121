import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import createApi from '../utils/axios';
import { IProductDetail, IProductRequest, IProductsResponse } from '../types/interfaces/api/product.interface';

const productApi = createApi('/products');

export const getProducts = async (pageParams?: { page?: number; limit?: number }): Promise<IProductsResponse> => {
  const { data } = (await productApi({
    method: METHODS.GET,
    url: '/',
    params: { limit: pageParams?.limit ? pageParams.limit : 10, page: pageParams?.page ? pageParams.page : 1 },
  })) as AxiosResponse<IProductsResponse>;
  return data;
};

export const getOwnProducts = async (pageParams?: {
  page?: number;
  limit?: number;
  q?: string;
  sort?: string;
}): Promise<IProductsResponse> => {
  const { data } = (await productApi({
    method: METHODS.GET,
    url: '/me',
    params: {
      limit: pageParams?.limit ? pageParams.limit : 9,
      page: pageParams?.page ? pageParams.page : 1,
      q: pageParams?.q ? pageParams.q : '',
      sort: pageParams?.sort ? pageParams.sort : '',
    },
  })) as AxiosResponse<IProductsResponse>;
  return data;
};

export const deleteProduct = async (id: string) => {
  await productApi({
    url: `/${id}`,
    method: METHODS.DELETE,
  });
};

export const productById = async (productId: string): Promise<IProductDetail> => {
  const { data } = (await productApi({
    url: `/${productId}`,
    method: METHODS.GET,
  })) as AxiosResponse<IProductDetail>;
  return data;
};

export const createProduct = async (formBody: IProductRequest): Promise<IProductDetail> => {
  const { data } = (await productApi({
    data: formBody,
    headers: {
      'Content-Type': 'application/json',
    },
    url: '/',
    method: METHODS.POST,
  })) as AxiosResponse<IProductDetail>;
  return data;
};

import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import CartCard from '../components/Cart/CartCard';
import '../styles/carts.css';
import Button from '../components/Button';
import FilterByDate from '../components/Products/FilterByDate';
import Pagination from '../components/pagination';
import { getCarts } from '../api/cart';
import { ICartDetail } from '../types/interfaces/api/cart.interface';
import * as toast from '../utils/toast';
import { Logger } from '../utils/logger.util';
import { QueryKeyString } from '../types/enums/query-key-string.enum';

const logger = new Logger('Cart');

export default function Cart() {
  const [carts, setCarts] = useState<ICartDetail[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortByDate, setSortByDate] = useState<string>();
  const [sortType, setSortType] = useState('Descending');
  const { refetch } = useQuery(
    [QueryKeyString.CART_DATA, currentPage, sortByDate],
    async () => getCarts({ page: currentPage, sort: sortByDate }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setCarts(data.docs);
        setTotalDocs(data.totalDocs);
        setTotalPages(data.totalPages);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );
  useEffect(() => {
    refetch().catch(logger.error);
  }, [currentPage, sortByDate]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Carts - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="cartSection">
        {carts.length > 0 ? (
          <>
            <div className="sectionHead">
              <p className="sectionName">Your Cart</p>
              <p className="sectionDesc">The items you have added to cart.</p>
            </div>
            <div className="sectionCont">
              <div className="pageFunctions">
                <div className="pageFunction">
                  <FilterByDate
                    onOrderClick={(e) => {
                      const elem: HTMLElement = e.currentTarget;
                      if (elem.dataset.value === 'createdAt:asc') setSortType('Ascending');
                      if (elem.dataset.value === 'createdAt:desc') setSortType('Descending');
                      setSortByDate(elem.dataset.value);
                    }}
                    sortType={sortType}
                  />
                </div>
              </div>
              <div className="cartCardWrapper">
                {carts.map((cart) => (
                  <CartCard key={cart._id} {...cart} />
                ))}
              </div>
              <div className="paginationContainer">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalDocs}
                  pageSize={9}
                  totalPages={totalPages}
                  onPageChange={(page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="sectionHeadEmpty">
            <FontAwesomeIcon icon={faCartShopping} className="emptyCart Cart" />
            <FontAwesomeIcon icon={faExclamationCircle} className="emptyCart Exclamation" />
            <p className="sectionNameEmpty">Seems like you don&#8217;t have any items carts.</p>
            <p className="sectionDescEmpty">Add items to cart to order items.</p>
            <Link to="/">
              <Button buttonWidth="30vw">Back to Home</Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

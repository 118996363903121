import { useWallet } from '@solana/wallet-adapter-react';
import { useState } from 'react';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { useZebec } from '../../hooks/useSolana';
import { Logger } from '../../utils/logger.util';

const logger = new Logger('Zebec_Wallet');

function Zebec() {
  const { publicKey } = useWallet();
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const { zebecBalance, zebecWallet, walletDeposit, walletWithdraw } = useZebec();

  return (
    <>
      <div>Your zebec wallet is: {zebecWallet || ''}</div>
      <div>Your balance is : {zebecBalance || 0} SOL</div>
      <InputField
        placeholder="deposit amount"
        value={depositAmount}
        onChange={(e) => {
          if (e.target.value === '') setDepositAmount(0);
          if (+e.target.value) setDepositAmount(+e.target.value);
        }}
        width="450px"
        inputType="number"
        step="0.01"
      />
      <Button
        onClick={() => {
          walletDeposit(depositAmount).catch(logger.error);
        }}
        disabled={!publicKey || !depositAmount}
      >
        Deposit {depositAmount || 0} SOL from {publicKey?.toBase58()}
      </Button>
      <InputField
        placeholder="withdraw amount"
        value={withdrawAmount}
        onChange={(e) => {
          if (e.target.value === '') setWithdrawAmount(0);
          if (+e.target.value) setWithdrawAmount(+e.target.value);
        }}
        width="450px"
        inputType="number"
        step="0.01"
      />
      <Button
        onClick={() => {
          walletWithdraw(withdrawAmount).catch(logger.error);
        }}
        disabled={!publicKey || !withdrawAmount}
      >
        Withdraw {withdrawAmount || 0} SOL to {publicKey?.toBase58()}
      </Button>
    </>
  );
}

export default Zebec;

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DashboardHeader from '../../components/DashboardHeader';
import '../../styles/tabs.css';

export default function Dashboard() {
  const location = useLocation();
  const [dashboardVisibility, setdashboardVisibility] = useState(false);
  useEffect(() => {
    if (location.pathname.match(/dashboard$/)) setdashboardVisibility(true);
    else setdashboardVisibility(false);
  }, [location]);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Dashboard - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <DashboardHeader />
      {dashboardVisibility ? <div className="content">This is the dashboard.</div> : ''}
      <Outlet />
    </>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface buttonProps {
  buttonPadding?: string;
  buttonWidth?: string;
  bgColor?: string;
  textColor?: string;
  borderRadius?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  onLoadingText?: string;
  disabled?: boolean;
  name?: string;
}

export default function Button({
  buttonPadding,
  buttonWidth,
  bgColor,
  textColor,
  borderRadius,
  children,
  onClick,
  loading,
  onLoadingText,
  disabled,
  name,
}: buttonProps) {
  return (
    <button
      className="ctaBtn"
      type="submit"
      disabled={loading || disabled}
      onClick={onClick}
      style={{
        width: buttonWidth,
        backgroundColor: bgColor,
        color: textColor,
        borderRadius,
        padding: buttonPadding,
      }}
      name={name}
    >
      {loading && <FontAwesomeIcon className="fa-spin spinnerIcon" icon={faSpinner} />}
      {loading && onLoadingText}
      {!loading && children}
    </button>
  );
}

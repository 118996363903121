import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faList, faMoneyBillWave, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button';
import '../styles/home.css';
import InfiniteProducts from '../components/Products/InfiniteProducts';
import { getLocalStorage, removeLocalStorage } from '../utils/storage';
import { createBulkCart } from '../api/cart';
import { useCustomMutation } from '../hooks/useCustomMutation';
import { ICreateCart } from '../types/interfaces/api/cart.interface';
import useAuth from '../hooks/useAuth';

export default function Home() {
  const { isAuthenticated } = useAuth();
  const { mutate: addTocartBulk } = useCustomMutation({
    api: createBulkCart,
    onSuccess: () => {
      removeLocalStorage('carts');
    },
  });
  useEffect(() => {
    if (isAuthenticated && getLocalStorage('carts')) {
      const cartBulk = JSON.parse(getLocalStorage('carts') || '[]') as ICreateCart[];
      addTocartBulk(cartBulk);
    }
  }, [isAuthenticated]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Rent Store - Ultimate Renting Experience</title>
        </Helmet>
      </HelmetProvider>
      {!isAuthenticated ? (
        <div className="mainContainer">
          <div className="topPart">
            <h1>
              <span>Renting</span> has never been that easier.
            </h1>
            <p>Get the ultimate renting experience through our customer-oriented service.</p>
            <div className="buttonContainer">
              <Link to={'/register'}>
                <Button>Get Started</Button>
              </Link>
            </div>
          </div>
          <div className="cards">
            <div className="card">
              <FontAwesomeIcon icon={faUserPlus} className="featureIcon" />
              <p className="featureName">Register</p>
            </div>
            <div className="card">
              <FontAwesomeIcon icon={faList} className="featureIcon" />
              <p className="featureName">List your item to rent</p>
            </div>
            <div className="card">
              <FontAwesomeIcon icon={faMoneyBillWave} className="featureIcon" />
              <p className="featureName">Get paid</p>
            </div>
          </div>
          <div className="mouse_scroll">
            <div className="mouse">
              <div className="wheel"></div>
            </div>
            <div>
              <span className="m_scroll_arrows first"></span>
              <span className="m_scroll_arrows second"></span>
              <span className="m_scroll_arrows third"></span>
            </div>
          </div>
          <p className="scrollText">SCROLL DOWN</p>
        </div>
      ) : (
        ''
      )}
      <section>
        <div className="sectionHead">
          <p className="sectionName">Products</p>
          <p className="sectionDesc">Rent an item within a minute.</p>
        </div>
        <InfiniteProducts />
      </section>
    </>
  );
}

import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Params, useParams } from 'react-router-dom';
import * as toast from '../utils/toast';
import Button from '../components/Button';
import spinner from '../images/spinner.gif';
import { productById as getProduct } from '../api/products';
import { IProductDetail } from '../types/interfaces/api/product.interface';
import ProductImageDisplay from '../components/ProductImageDisplay';
import '../styles/product.css';
import { useCustomMutation } from '../hooks/useCustomMutation';
import { createCart } from '../api/cart';
import { ICartDetail, ICreateCart } from '../types/interfaces/api/cart.interface';
import { createOrder } from '../api/order';
import ICreateOrder, { IOrderDetail } from '../types/interfaces/api/order.interface';
import AddToCart from '../components/Cart/AddToCart';
import { QueryKeyString } from '../types/enums/query-key-string.enum';

export default function Product() {
  const { productId }: Readonly<Params<string>> = useParams();
  const [product, setProduct] = useState<IProductDetail>();
  if (!productId) {
    return null;
  }
  const postFetch = useQuery(
    [QueryKeyString.PRODUCT_DATA, productId],
    async () => {
      if (!productId) throw new Error('No product id');
      return getProduct(productId);
    },
    {
      onSuccess: (data) => {
        if (!data) return;
        setProduct(data);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );

  const { mutateAsync: createCartMutate, isLoading: cartLoading } = useCustomMutation<ICreateCart, ICartDetail>({
    api: createCart,
    error: 'Error',
  });

  const { mutateAsync: createOrderMutate, isLoading: orderLoading } = useCustomMutation<ICreateOrder, IOrderDetail>({
    api: createOrder,
    success: 'Offer sent succesfully.',
    error: 'Error',
  });

  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const createOrderOnClick = useCallback(async () => {
    if (!product || quantity === 0) {
      toast.error('Invalid Order');
      return;
    }
    const cart = await createCartMutate({ product: product._id, quantity });
    const cartId = cart._id;
    await createOrderMutate({ carts: [cartId] });
  }, [product, quantity]);

  const increaseQuantity = () => {
    if (!product || product.quantity <= quantity) {
      return;
    }

    setQuantity((prevQuantity) => Math.max(0, prevQuantity + 1));
    setTotalPrice((prevQuantityPrice) => prevQuantityPrice + product.rentPrice);
  };

  const decreaseQuantity = () => {
    if (!product || quantity <= 0) return;
    setQuantity((prevQuantity) => Math.max(0, prevQuantity - 1));
    setTotalPrice((prevQuantityPrice) => prevQuantityPrice - product.rentPrice);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(+e.target.value);
  };
  function TotalComponent() {
    return <div className="quantityPrice">Total Price : ${totalPrice}</div>;
  }

  if (!product) return null;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Product - Rent Store</title>
        </Helmet>
      </HelmetProvider>

      <div className="productComponentContainer">
        <ProductImageDisplay productImg={product.medias} />
        <div className="productByIdInfo">
          <div className="InfoContainer">
            <div className="infoHeader">
              {postFetch.isLoading ? <img src={spinner} className="loadingIcon" /> : ''}
              <div className="productName">{product.name}</div>
              <div className="deliveryDet">
                <FontAwesomeIcon icon={faTruck} className={product.delivery ? 'featureIcon' : 'noDelivery'} />
              </div>
            </div>
            <div className="postedBy">
              Posted By : {product.renter.firstName} {product.renter.lastName}
            </div>
            <div className="postedTime">{product.publishedAt}</div>
            <div className="productByIdPrice">
              <span>${product.rentPrice}</span>
            </div>
            <div className="orderArea">
              <div className="quantity">
                <div className="qty">Stock : {product.quantity}</div>
                <form className="quantityCounter">
                  <div className="value-button" id="decrease" onClick={decreaseQuantity}>
                    -
                  </div>
                  <input type="number" id="number" min="0" value={quantity} onChange={handleChange} />
                  <div className="value-button" id="increase" onClick={increaseQuantity}>
                    +
                  </div>
                </form>
              </div>
              <TotalComponent />
              <div className="buttonHolder">
                <Button
                  loading={orderLoading || cartLoading}
                  onLoadingText="Sending order"
                  onClick={() => {
                    createOrderOnClick().catch(() => {
                      toast.error('Error');
                    });
                  }}
                  buttonWidth="180px "
                  buttonPadding="18px 50px"
                  borderRadius="7px"
                >
                  Rent Now
                </Button>
                <AddToCart product={product} quantity={quantity} />
              </div>
            </div>
            <div className="productInfo">
              <div className="about">About this Item :</div>
              {product.description}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faMoneyBill, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const TransactionSidebar = () => {
  const location = useLocation();
  return (
    <div>
      <div className="sidebar-container">
        <Link to="/dashboard/transactions">
          <div className={`sidebar-item ${location.pathname === '/dashboard/transactions' ? 'active-sidebar-item' : ''}`}>
            <FontAwesomeIcon className="sidebar-item-icon" icon={faMoneyBill} />
            <p className="sidebar-item-name">Transactions</p>
          </div>
        </Link>
        <Link to="/dashboard/transactions/zebec-wallet">
          <div
            className={`sidebar-item ${
              location.pathname === '/dashboard/transactions/zebec-wallet' ? 'active-sidebar-item' : ''
            }`}
          >
            <FontAwesomeIcon className="sidebar-item-icon" icon={faWallet} />
            <p className="sidebar-item-name">Zebec Wallet</p>
          </div>
        </Link>
        <Link to="/dashboard/transactions/wallets">
          <div
            className={`sidebar-item ${
              location.pathname === '/dashboard/transactions/wallets' ? 'active-sidebar-item' : ''
            }`}
          >
            <FontAwesomeIcon className="sidebar-item-icon" icon={faAddressBook} />
            <p className="sidebar-item-name">Wallets</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TransactionSidebar;

import { useQueryClient } from 'react-query';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { deleteWallet } from '../../api/wallets';
import { Logger } from '../../utils/logger.util';
import useModal from '../../hooks/useModal';
import Alert from '../Alert';
import Button from '../Button';

const logger = new Logger('DeleteCart');

export default function DeleteWallet({ walletId }: { walletId: string }) {
  const queryClient = useQueryClient();

  const { isShowing: showDeleteModal, toggle: toggleDeletePopup } = useModal();

  const { mutate: removeWallet, isLoading } = useCustomMutation({
    api: deleteWallet,
    success: 'The wallet has been deleted sucessfully.',
    onSuccess: () => {
      queryClient.invalidateQueries(['walletsData']).catch((err) => logger.error(err));
    },
  });

  const onDeleteClick = () => {
    removeWallet(walletId);
  };

  return (
    <>
      <Button bgColor="var(--red)" buttonWidth="50px" buttonPadding="16.2px 0px" onClick={toggleDeletePopup}>
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      <Alert
        loading={isLoading}
        onLoadingText="Deleting Wallet"
        actionDetail="Are you sure you want to delete this Wallet?"
        alertTitle="Delete Wallet"
        hideAlert={toggleDeletePopup}
        showAlert={showDeleteModal}
        actionButtonHandler={() => onDeleteClick()}
        actionButtonName="Delete Wallet"
        buttonType="red"
        cancelButtonHandler={toggleDeletePopup}
      />
    </>
  );
}

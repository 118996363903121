import { PropsWithChildren } from 'react';
import TransactionSidebar from './TransactionSidebar';
import '../../styles/Dashboard/transactions.css';

interface ITransactionLayoutProps {
  heading: string;
  description: string;
}

const TransactionLayout = ({ heading, description, children }: PropsWithChildren<ITransactionLayoutProps>) => (
  <div className="content sectionCont">
    <div className="secHead">{heading}</div>
    <div className="secDesc">{description}</div>
    <div className="section-container">
      <div className="transaction-rows">
        <div className="transaction-small-column">
          <TransactionSidebar />
        </div>
        <div className="transaction-large-column">{children}</div>
      </div>
    </div>
  </div>
);

export default TransactionLayout;

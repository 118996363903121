import '../styles/header.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBars, faCircle } from '@fortawesome/free-solid-svg-icons';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import logo from '../images/logo.png';
import Notification from './Notification';
// import Breadcrumbs from './Breadcrumbs';
import * as toast from '../utils/toast';
import CartIcon from './Cart/CartIcon';
import useAuth from '../hooks/useAuth';

export default function DashboardHeader() {
  const { logout } = useAuth();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <>
      <div className="headerWrapper dashboardHeaderWrapper">
        <nav className="navigation">
          <Link to="/">
            <img src={logo} className="headerLogo" />
          </Link>
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <FontAwesomeIcon icon={faBars} className="featureIcon" />
          </button>
          <div className={isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'}>
            <ul>
              <li>
                <Link className="navLinks" to="/dashboard">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link className="navLinks" to="/dashboard/products">
                  Products
                </Link>
              </li>
              <li>
                <Link className="navLinks" to="/dashboard/orders">
                  Orders
                </Link>
              </li>
              <li>
                <Link className="navLinks" to="/dashboard/transactions">
                  Transactions
                </Link>
              </li>
              <li className="iconListFirst">
                <Notification />
              </li>
              <li className="iconList">
                <Link
                  onClick={() => {
                    logout();
                    toast.success('Logged out succefsully.');
                  }}
                  to="/"
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} className="dashboardIcon" />
                </Link>
              </li>
              <li className="iconList">
                <CartIcon />
              </li>
              <WalletMultiButton />
              {
                // <li className="iconList">
                // <FontAwesomeIcon icon={faCircle} className="userAvatar" />
                // </li>
              }
            </ul>
          </div>
        </nav>
      </div>
      <div className="breadcrumbCont content">{/* <Breadcrumbs /> */}</div>
    </>
  );
}

import { IWalletDetails } from '../../types/interfaces/api/wallets.interface';
import AddorPatchWallet from './AddorPatchWallet';
import useModal from '../../hooks/useModal';
import DeleteWallet from './DeleteWallet';
import Button from '../Button';

export default function WalletCard(wallet: IWalletDetails) {
  const { isShowing: showEditWalletPopup, toggle: toggleEditWalletPopup } = useModal();

  return (
    <div className="walletCard">
      <div className="walletName">{wallet.name}</div>
      <div className="walletAddress">{wallet.wallet}</div>
      <div className="walletActions">
        <div className="deleteWalletBtn">
          <DeleteWallet walletId={wallet._id} />
        </div>
        <div className="editWalletBtn">
          <Button buttonPadding="17px 0px" onClick={toggleEditWalletPopup}>
            Edit Wallet
          </Button>
          <AddorPatchWallet
            onLoadingText="Editing Wallet"
            popupTitle="Edit Wallet"
            hidePopup={toggleEditWalletPopup}
            showPopup={showEditWalletPopup}
            addWallet={false}
            actionButtonName="Save Changes"
            cancelButtonHandler={toggleEditWalletPopup}
            walletId={wallet._id}
            defaultValues={{ name: wallet.name, wallet: wallet.wallet }}
          />
        </div>
      </div>
    </div>
  );
}

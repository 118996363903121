/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import useFormInput from '../../hooks/useFormInput';
import { createProduct } from '../../api/products';
import { IProductRequest } from '../../types/interfaces/api/product.interface';
import InputField from '../InputField';
import Button from '../Button';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import ImageUpload from '../ImageUploadModal';
import useModal from '../../hooks/useModal';

export default function PostProduct({
  imageState,
}: {
  imageState?: [string[], React.Dispatch<React.SetStateAction<string[]>>];
}) {
  const [imageIds, setImageIds] = imageState || useState<string[]>([]);
  const { isShowing: showModal, toggle: toggleImageUpload } = useModal();
  const nav = useNavigate();
  const {
    name: { value: name, handleChange: onNameChange },
    description: { value: description, handleChange: onDescriptionChange },
    price: { value: price, handleChange: onPriceChange },
    quantity: { value: quantity, handleChange: onQuantityChange },
    delivery: { value: delivery, setValue: setDelivery },
  } = useFormInput([
    { name: 'name' },
    { name: 'description' },
    { name: 'price' },
    { name: 'quantity' },
    { name: 'delivery', initialState: false },
  ]);
  const [inputdata, setInputData] = useState<IProductRequest>();

  useEffect(() => {
    setInputData({
      name,
      description,
      rentPrice: +price,
      quantity: +quantity,
      delivery,
      medias: imageIds,
    });
  }, [name, description, price, quantity, delivery, imageIds]);

  const { mutate: createPost, isLoading } = useCustomMutation({
    api: createProduct,
    success: 'Product created succesfully.',
    error: 'Error',
    onSuccess: () => {
      nav('/dashboard/products');
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputdata) return;
    createPost(inputdata);
  };

  return (
    <>
      <div className={`responsiveRow ${imageIds.length === 0 ? `productsCenter` : ''}`}>
        <div className="responsiveColumn">
          <form className="postProductForm" onSubmit={handleSubmit}>
            <InputField inputType="text" name="name" placeholder="Name" onChange={onNameChange} />
            <InputField inputType="text" name="description" placeholder="Description" onChange={onDescriptionChange} />
            <InputField inputType="number" step="0.01" name="price" placeholder="Price" onChange={onPriceChange} />
            <div className="customRow">
              <div className="deliveryDetails">
                <label className="deliveryOption">Delivery</label>
                <InputField
                  inputType="checkbox"
                  name="delivery"
                  placeholder="Delivery"
                  onChange={(e) => {
                    if (e.target.checked) setDelivery(true);
                    else setDelivery(false);
                  }}
                />
              </div>
              <button className="uploadImg" type="button" onClick={toggleImageUpload}>
                Upload product images
              </button>
            </div>
            <InputField inputType="number" name="name" placeholder="Quantity" onChange={onQuantityChange} />
            <div className="actionBtnCont">
              <Button onLoadingText="Creating the product" loading={isLoading}>
                Create the product
              </Button>
            </div>
          </form>
        </div>
        <div className="responsiveColumn">
          <div className="imageContainer">
            <div className="responsiveRow2">
              {imageIds.slice(0, 4).map((imageId) => (
                <div className="imgList" key={imageId}>
                  <img src={`/api/medias/${imageId}`} />
                  <button
                    onClick={() => {
                      setImageIds((prev) => {
                        if (prev.indexOf(imageId) < 0) return prev;
                        prev.splice(prev.indexOf(imageId), 1);
                        return [...prev];
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ImageUpload hide={toggleImageUpload} show={showModal} imageState={[imageIds, setImageIds]} />
    </>
  );
}

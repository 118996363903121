import { useQueryClient } from 'react-query';
import { deleteProduct } from '../../api/products';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useModal from '../../hooks/useModal';
import { Logger } from '../../utils/logger.util';
import Alert from '../Alert';
import Button from '../Button';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

export default function DeleteProduct({ productData }: { productData: string }) {
  const queryClient = useQueryClient();
  const { isShowing: showDeleteModal, toggle: toggleDeletePopup } = useModal();
  const logger = new Logger('DeleteProduct');
  const { mutate: deleteTheProduct, isLoading } = useCustomMutation({
    api: deleteProduct,
    success: 'The product has been succesfully deleted.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyString.PRODUCT_DATA]).catch((err) => logger.error(err));
    },
  });

  const onDeleteHandler = (id: string) => {
    deleteTheProduct(id);
  };
  return (
    <>
      <div className="buttonCont">
        <Button bgColor="#F54949" onClick={toggleDeletePopup}>
          Delete Product
        </Button>
      </div>
      <Alert
        loading={isLoading}
        onLoadingText="Deleting Product"
        actionDetail="Are you sure you want to delete this product?"
        alertTitle="Delete Product"
        hideAlert={toggleDeletePopup}
        showAlert={showDeleteModal}
        actionButtonHandler={() => onDeleteHandler(productData)}
        actionButtonName="Delete Product"
        buttonType="red"
        cancelButtonHandler={toggleDeletePopup}
      />
    </>
  );
}

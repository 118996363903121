import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { ORDER_STATUS } from '../../types/enums/order-status.enum';

export default function FilterByStatus({
  onStatusClick,
  filterType,
}: {
  filterType: string;
  onStatusClick: React.MouseEventHandler<HTMLElement>;
}) {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div
      className="filterDropdown"
      onClick={() => {
        setOpenFilter((prevOpen) => !prevOpen);
      }}
    >
      <button className="filterBtn">
        <FontAwesomeIcon icon={faSort} className="filterIcon" />
        <p>
          Filter by Status <span className="sortType">(Currently: {filterType})</span>{' '}
        </p>
      </button>
      <div className={`filterDropdown-body ${openFilter ? 'open' : ''}`}>
        {Object.values(ORDER_STATUS).map((item, index) => (
          <div className="filterDropdown-item" key={index} onClick={onStatusClick} data-value={item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

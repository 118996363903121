import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useWallet } from '@solana/wallet-adapter-react';
import IncomingTransaction from '../../../components/Transactions/IncomingTransaction';
import OutgoingTransaction from '../../../components/Transactions/OutgoingTransaction';
import TransactionLayout from '../../../components/Transactions/TransactionsLayout';
import WalletNotConnected from '../../../components/WalletNotConnected';

const TransactionIndex = () => {
  const [active, setActive] = useState('incoming');
  const { publicKey } = useWallet();

  return (
    <TransactionLayout heading="Transactions" description="Handle your incoming and outgoing transactions.">
      {publicKey ? (
        <Tabs className="tabsContainer">
          <TabList className="tabHeaderTransaction">
            <Tab
              onClick={() => (active === 'incoming' ? '' : setActive('incoming'))}
              className={active === 'incoming' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Incoming
            </Tab>
            <Tab
              onClick={() => (active === 'outgoing' ? '' : setActive('outgoing'))}
              className={active === 'outgoing' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Outgoing
            </Tab>
          </TabList>
          <TabPanel className="tabsContent">
            <IncomingTransaction />
          </TabPanel>
          <TabPanel className="tabsContent">
            <OutgoingTransaction />
          </TabPanel>
        </Tabs>
      ) : (
        <WalletNotConnected />
      )}
    </TransactionLayout>
  );
};

export default TransactionIndex;

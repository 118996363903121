import { Link } from 'react-router-dom';
import { IProductCardFields } from '../types/interfaces/api/product.interface';
import '../styles/productCard.css';
import defaultProductImage from '../images/defaultProductImage.png';
import imageURL from '../utils/imageUrl';
import stringTruncate from '../utils/stringTruncate';
import AddToCart from './Cart/AddToCart';

export default function ProductCard(product: IProductCardFields) {
  const [mainImage] = product.medias;

  return (
    <div className="productCards">
      <div className="productCard">
        <div className="productImage">
          <Link to={`/products/${product._id}`}>
            <img
              src={
                !product.medias || product.medias.length === 0
                  ? defaultProductImage
                  : imageURL(mainImage) ?? defaultProductImage
              }
              alt="Product Image"
            />
          </Link>
        </div>
        <div className="productDetails">
          <Link to={`/products/${product._id}`}>
            <p className="cardProductName">{stringTruncate(product.name, 50)}</p>
          </Link>
          <p className="cardProductPrice">$ {product.rentPrice}</p>
          <div className="secondRow">
            <div className="stockCounter">
              <p>Stock:&nbsp;</p>
              <span>{product.quantity}</span>
            </div>
            <div className="addToCartButton">
              <AddToCart product={product} quantity={1} cardIcon={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

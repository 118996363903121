import { faExclamationCircle, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function WalletNotConnected() {
  return (
    <div className="walletNotConnected">
      <div className="zebecWalletTitle">CONNECT YOUR WALLET</div>
      <div className="sectionHeadEmpty">
        <FontAwesomeIcon icon={faWallet} className="emptyCart Cart" />
        <FontAwesomeIcon icon={faExclamationCircle} className="emptyCart Exclamation" />
        <p className="sectionNameEmpty">Looks like you are not connected to your wallet.</p>
        <p className="sectionDescEmpty">Connect To Your Wallet To Continue.</p>
        <div className="ConnectToPhantom">
          <WalletMultiButton />
        </div>
      </div>
    </div>
  );
}

export default WalletNotConnected;

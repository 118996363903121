import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import { IWalletsRequest, IWalletsResponse } from '../types/interfaces/api/wallets.interface';
import createApi from '../utils/axios';

const walletsApi = createApi('/wallets');

export const getWallets = async (): Promise<IWalletsResponse> => {
  const { data } = (await walletsApi({
    url: '/',
    method: METHODS.GET,
  })) as AxiosResponse<IWalletsResponse>;

  return data;
};

export const createWallet = async (walletDetails: IWalletsRequest): Promise<IWalletsResponse> => {
  const { data } = (await walletsApi({
    url: '/',
    method: METHODS.POST,
    data: walletDetails,
  })) as AxiosResponse<IWalletsResponse>;

  return data;
};

export const updateWallet = async ({ walletId, walletDetails }: { walletId: string; walletDetails: IWalletsRequest }) => {
  const { data } = (await walletsApi({
    url: `/${walletId}`,
    method: METHODS.PATCH,
    data: walletDetails,
  })) as AxiosResponse<IWalletsResponse>;
  return data;
};

export const deleteWallet = async (id: string) => {
  await walletsApi({
    url: `/${id}`,
    method: METHODS.DELETE,
  });
};

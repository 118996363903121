import { useWallet } from '@solana/wallet-adapter-react';
import { IIncomingTransaction } from '../../types/interfaces/api/transaction.interface';
import Button from '../Button';
import useModal from '../../hooks/useModal';
import WithDrawModal from './WithDrawModal';
import * as toast from '../../utils/toast';

function IncomingTransactionCard(details: IIncomingTransaction) {
  const { publicKey } = useWallet();
  const { isShowing: showWithdrawModal, toggle: toggleWithdrawPopup } = useModal();
  const noOperationStatus = new Date(details.startTime).getTime() > Date.now();

  return (
    <>
      <div className="incomingTransactionContainer">
        <div className="incomingTransactionItem">
          <div className="incomingTransactionItemInfo">
            <p className="incomingTransactionItemName">{details.order.cart.product.name}</p>
            <p className="incomingTransactionItemDescription">#{details.order._id}</p>
          </div>
          <div className="incomingTransactionOrderInfo">
            <div className="incomingTransactionItemQuantity">
              <p className="incomingTransactionItemQuantityTitle">Rentee</p>
              <p className="incomingTransactionItemQuantityTitle">{`${details.order?.rentee?.firstName} ${details.order?.rentee?.lastName}`}</p>
            </div>
            <div className="incomingTransactionItemPrice">
              <p className="incomingTransactionItemPriceTitle">Total Price</p>
              <p className="incomingTransactionItemPriceText">{details.amount} SOL</p>
            </div>
            <div className="incomingTransactionItemDate">
              <p className="incomingTransactionItemDateTitle">Start Date</p>
              <p className="incomingTransactionItemDateText">{new Date(details.startTime).toLocaleString()}</p>
            </div>
            <div className="incomingTransactionItemDate">
              <p className="incomingTransactionItemDateTitle">End Date</p>
              <p className="incomingTransactionItemDateText">{new Date(details.endTime).toLocaleString()}</p>
            </div>
          </div>
          <div className="incomingTransactionButtonContainer">
            <Button
              onClick={() => {
                if (publicKey?.toBase58() !== details.receiver) {
                  toast.error(`Connected wallet is different from ${details.receiver}`);
                  return;
                }
                toggleWithdrawPopup();
              }}
              disabled={noOperationStatus}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </div>
      <WithDrawModal
        hide={toggleWithdrawPopup}
        show={showWithdrawModal}
        pda={details.pda}
        sender={details.sender}
        transactionId={details._id}
        amount={details.amount}
      />
    </>
  );
}

export default IncomingTransactionCard;

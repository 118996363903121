import { useState } from 'react';
import { useQuery } from 'react-query';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getMedia } from '../api/media';
import { IMediaDetail, IMediaResponse } from '../types/interfaces/api/media.interface';
import * as toast from '../utils/toast';
import FileUpload from './FileUpload';
import Modal from './Modal';
import Pagination from './pagination';
import { QueryKeyString } from '../types/enums/query-key-string.enum';

const ImageUploadModal = ({
  imageState,
  hide,
  show,
}: {
  show: boolean;
  hide: () => void;
  imageState?: [string[], React.Dispatch<React.SetStateAction<string[]>>];
}) => {
  const [active, setActive] = useState('upload');
  const [files, setFiles] = useState<FileList | null>(null);
  const [images, setImages] = useState<IMediaDetail[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [imageIds, setImageIds] = imageState || useState<string[]>([]);

  useQuery(
    [QueryKeyString.IMAGE_DATA, currentPage],
    async () =>
      getMedia({ page: currentPage }).catch((err: string) => {
        toast.error(err);
      }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setImages(data.docs);
        setTotalDocs(data.totalDocs);
        setTotalPages(data.totalPages);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );
  return (
    <>
      <Modal
        isShowing={show}
        hide={() => {
          hide();
          setActive('upload');
        }}
        title="Select an image"
      >
        <Tabs className="tabsContainer">
          <TabList className="tabHeader">
            <Tab
              onClick={() => (active === 'upload' ? '' : setActive('upload'))}
              className={active === 'upload' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Upload Image
            </Tab>
            <Tab
              onClick={() => (active === 'choose' ? '' : setActive('choose'))}
              className={active === 'choose' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Choose Image
            </Tab>
          </TabList>
          <TabPanel className="tabsContent">
            <FileUpload state={[files, setFiles]} />
          </TabPanel>
          <TabPanel className="tabsContent">
            <div className="imageSelectionCont">
              {images.map((item) => (
                <div className="imageSelection" key={item._id}>
                  <div className="imageSS">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        const indexOfImage = imageIds.indexOf(item._id);
                        if (e.target.checked) {
                          setImageIds((prev) => [...prev, item._id]);
                        }
                        if (!e.target.checked && indexOfImage > -1) {
                          setImageIds((prev) => {
                            prev.splice(indexOfImage, 1);
                            return [...prev];
                          });
                        }
                      }}
                      className="imageSelect"
                      checked={imageIds.indexOf(item._id) > -1}
                    />
                    <img src={item.filepath} />
                  </div>
                </div>
              ))}
            </div>
            <div className="imagePaginationCont">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalDocs}
                pageSize={4}
                totalPages={totalPages}
                onPageChange={(page) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          </TabPanel>
        </Tabs>
      </Modal>
    </>
  );
};

export default ImageUploadModal;

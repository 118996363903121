import { useCallback, useState } from 'react';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import * as toast from '../../utils/toast';
import { Logger } from '../../utils/logger.util';
import { useZebec } from '../../hooks/useSolana';
import { BUFFER_SECONDS } from '../../constants';

const logger = new Logger('Send_Zebec');

function Send() {
  const [amount, setAmount] = useState(0);
  const [receiver, setReceiver] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [pda, setPda] = useState('');
  const [paused, setPaused] = useState(true);

  const [orderId] = useState<string>('630654721eee68f292272355');
  const [transactionId] = useState<string>('630663951eee68f29227241e');

  const { streamPause, streamStart, streamResume, streamStop } = useZebec();

  const copyPda = useCallback(async (): Promise<void> => {
    await navigator.clipboard.writeText(pda);
    toast.success('PDA copied to clipboard');
  }, [pda]);

  return (
    <>
      <InputField placeholder="receiver" value={receiver} onChange={(e) => setReceiver(e.target.value)} width="600px" />
      <InputField
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(+e.target.value)}
        width="450px"
        inputType="number"
        step="0.01"
      />
      <div className="date_time_container">
        <InputField
          placeholder="Start Date"
          inputType="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <InputField
          placeholder="Start Time"
          inputType="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>
      <div className="date_time_container">
        <InputField placeholder="End Date" inputType="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <InputField placeholder="End Time" inputType="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
      </div>
      <Button
        onClick={() => {
          streamStart({
            receiver,
            amount,
            startDate,
            startTime,
            endDate,
            endTime,
            orderId,
            bufferSeconds: BUFFER_SECONDS,
          })
            .then((data) => {
              if (data.pda) setPda(data.pda);
            })
            .catch(logger.error);
        }}
        disabled={!startDate || !startTime || !receiver || !endDate || !endTime}
      >
        Send {amount || 0} SOL
      </Button>
      <Button
        onClick={() => async () => {
          try {
            // eslint-disable-next-line no-unused-expressions
            paused
              ? await streamResume({
                  receiver,
                  pda,
                  transactionId,
                })
              : await streamPause({
                  receiver,
                  pda,
                  transactionId,
                });
          } catch (error) {
            logger.error(error);
          }
        }}
        disabled={!pda || !receiver}
      >
        {paused ? 'Resume' : 'Pause'}
      </Button>
      <Button
        onClick={() => {
          streamStop({
            receiver,
            pda,
            transactionId,
          }).catch(logger.error);
        }}
        disabled={!pda || !receiver}
      >
        Stop
      </Button>
      <Button
        onClick={() => {
          copyPda().catch(logger.error);
        }}
        disabled={!pda}
      >
        Copy PDA
      </Button>
    </>
  );
}

export default Send;

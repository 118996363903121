import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as toast from '../../utils/toast';
import '../../styles/Dashboard/incomingTransaction.css';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';
import { getIncomingTransaction } from '../../api/transaction';
import { IIncomingTransaction } from '../../types/interfaces/api/transaction.interface';
import FilterByDate from '../Products/FilterByDate';
import { Logger } from '../../utils/logger.util';
import Pagination from '../pagination';
import IncomingTransactionCard from './IncomingTransactionCard';

const logger = new Logger('IncomingTransaction');

export default function IncomingTransaction() {
  const [incomingTransaction, setIncomingTransaction] = useState<IIncomingTransaction[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortByDate, setSortByDate] = useState<string>();
  const [sortType, setSortType] = useState('Descending');
  const { refetch } = useQuery(
    [QueryKeyString.INCOMING_TRANSACTION_DATA, currentPage, sortByDate],
    async () =>
      getIncomingTransaction({ page: currentPage, sort: sortByDate }).catch((err: string) => {
        toast.error(err);
      }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setIncomingTransaction(data.docs);
        setTotalDocs(data.totalDocs);
        setTotalPages(data.totalPages);
      },
      onError: (err: Error) => {
        toast.error(err?.message);
      },
    }
  );

  useEffect(() => {
    refetch().catch(logger.error);
  }, [currentPage, sortByDate]);

  return (
    <>
      <div className="pageFunctions">
        <div className="pageFunction">
          <FilterByDate
            onOrderClick={(e) => {
              if (e.currentTarget.dataset.value === 'createdAt:asc') setSortType('Ascending');
              if (e.currentTarget.dataset.value === 'createdAt:desc') setSortType('Descending');
              setSortByDate(e.currentTarget.dataset.value);
            }}
            sortType={sortType}
          />
        </div>
      </div>
      <div className="cartCardWrapper">
        {incomingTransaction.map((details) => (
          <IncomingTransactionCard key={details._id} {...details} />
        ))}
      </div>

      <div className="paginationContainer">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalDocs}
          pageSize={9}
          totalPages={totalPages}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
}

import '../../styles/Dashboard/orders.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface ISearchOrder {
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterText: string;
}

export default function SearchOrder({ onFilter, filterText }: ISearchOrder) {
  return (
    <div className="searchBoxContainer">
      <input id="search" type="text" placeholder="Search Order" value={filterText} onChange={onFilter} />
      <FontAwesomeIcon icon={faSearch} className="inputIcon" />
    </div>
  );
}

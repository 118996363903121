import { FormEvent, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { uploadMedia } from '../api/media';
import * as toast from '../utils/toast';
import '../styles/uploadFile.css';
import Button from './Button';
import { useCustomMutation } from '../hooks/useCustomMutation';
import { Logger } from '../utils/logger.util';
import { QueryKeyString } from '../types/enums/query-key-string.enum';

interface FileUploadProps {
  state: [FileList | null, React.Dispatch<React.SetStateAction<FileList | null>>];
}

const logger = new Logger('FileUpload');

export default function FileUpload({ state }: FileUploadProps) {
  const queryClient = useQueryClient();
  const [files, setFiles] = state;
  const ref = useRef<HTMLInputElement>(null);
  const { mutate: fileUpload, isLoading } = useCustomMutation({
    api: uploadMedia,
    success: 'The media has been succesfully uploaded.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyString.IMAGE_DATA).catch((err) => logger.error(err));
    },
  });
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!files) {
      toast.error('No file selected');
      return;
    }
    fileUpload(files);
  };

  return (
    <form onSubmit={onSubmit}>
      <label
        className="customUploadBody"
        htmlFor="files"
        onChange={() => {
          if (!ref?.current) return;
          setFiles(ref.current.files);
        }}
      >
        <input type="file" id="files" multiple hidden ref={ref} />
        <div className="imgCont">
          <FontAwesomeIcon icon={faImage} className="imageIcon" />
        </div>
        <div className="imageDetail">
          {!files ? (
            <p>Click to upload the image</p>
          ) : (
            Array.from(files as unknown as File[]).map((item, index) => <p key={index}>{item.name}</p>)
          )}
        </div>
      </label>
      <div className="uploadImageCont">
        <Button loading={isLoading} onLoadingText="Uploading Media">
          Upload Media
        </Button>
      </div>
    </form>
  );
}

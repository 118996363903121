import '../styles/inputField.css';
import React from 'react';

interface InputFieldProps {
  placeholder?: string;
  marginLeft?: string;
  inputType?: string;
  marginTop?: string;
  width?: string;
  display?: string;
  name?: string;
  step?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  isRequired?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}

export default function InputField({
  placeholder,
  marginLeft,
  inputType,
  marginTop,
  width,
  display,
  onChange,
  name,
  isRequired,
  value,
  step,
  readOnly,
}: InputFieldProps) {
  return (
    <input
      style={{
        marginLeft,
        marginTop,
        width,
        display,
      }}
      className="inputField"
      type={inputType}
      placeholder={placeholder}
      onChange={onChange}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value={value}
      name={name}
      required={isRequired}
      step={step}
      readOnly={readOnly}
    />
  );
}

import { Connection, PublicKey } from '@solana/web3.js';
import { NativeStream, StreamTransactionResponse, ZEBEC_PROGRAM_ID } from '@zebec-protocol/stream';
import {
  StartStreamParams,
  StreamParams,
  WithDrawStreamParams,
  depositWithdrawNativeParams,
} from '../types/interfaces/zebec.interface';

export const initNativeStream = (walletProvider: unknown, rpcUrl: string): NativeStream => {
  const native: NativeStream = new NativeStream(walletProvider, rpcUrl);

  return native;
};

export const getZebecWallet = async (publicKey: PublicKey) => {
  const associatedEscrow = await PublicKey.findProgramAddress([publicKey.toBuffer()], new PublicKey(ZEBEC_PROGRAM_ID));

  const validProgramAddress = associatedEscrow[0].toBase58();
  return validProgramAddress;
};

/**
 *
 *@description This function is used to get the balance of a given account in the Zebec escrow in SOL lamports
 */
export const getZebecBalance = async (zebecWallet: string, connection: Connection) => {
  const zebecPublicKey = new PublicKey(zebecWallet);
  return connection.getBalance(zebecPublicKey);
};
export const depositToZebecWallet = async (
  native: NativeStream,
  { sender, amount }: depositWithdrawNativeParams
): Promise<StreamTransactionResponse> => {
  if (amount <= 0) {
    throw new Error('Amount must be greater than 0');
  }

  const deposit = await native.deposit({ sender, amount });

  return deposit;
};

export const withdrawFromZebecWallet = async (
  native: NativeStream,
  { sender, amount }: depositWithdrawNativeParams
): Promise<StreamTransactionResponse> => {
  if (amount <= 0) {
    throw new Error('Amount must be greater than 0');
  }

  const withdraw = await native.withdrawDepositedSol({ sender, amount });

  return withdraw;
};

export const startStream = async (
  native: NativeStream,
  streamOptions: StartStreamParams
): Promise<StreamTransactionResponse> => {
  if (streamOptions.start_time > streamOptions.end_time) {
    throw new Error('Start time must be before end time');
  }
  if (streamOptions.amount <= 0) {
    throw new Error('Amount must be greater than 0');
  }
  if (streamOptions.start_time < Math.floor(Date.now() / 1000) + 60) {
    throw new Error('Start time must be in the future and atleast 1min before now');
  }

  const response = await native.init(streamOptions);

  return response;
};

export const pauseStream = async (native: NativeStream, pauseOptions: StreamParams): Promise<StreamTransactionResponse> => {
  const response = await native.pause(pauseOptions);

  return response;
};

export const resumeStream = async (
  native: NativeStream,
  resumeOptions: StreamParams
): Promise<StreamTransactionResponse> => {
  const response = await native.resume(resumeOptions);

  return response;
};

export const stopStream = async (native: NativeStream, stopOptions: StreamParams): Promise<StreamTransactionResponse> => {
  const response = await native.cancel(stopOptions);

  return response;
};

export const withdrawStream = async (
  native: NativeStream,
  withdrawOptions: WithDrawStreamParams
): Promise<StreamTransactionResponse> => {
  if (withdrawOptions.amount <= 0) {
    throw new Error('Amount must be greater than 0');
  }

  const response = await native.withdraw(withdrawOptions);

  return response;
};

import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import createApi from '../utils/axios';
import {
  ICreateTransactionLogRequest,
  IIncomingTransactionResponse,
  IOrderTransactionRequest,
  IOrderTransactionResponse,
  ITransactionDetail,
} from '../types/interfaces/api/transaction.interface';

const transactionApi = createApi('/orders');

export const getTransactions = async (orderId: IOrderTransactionRequest['orderId']) => {
  const { data } = (await transactionApi({
    url: `/${orderId}/transactions`,
    method: METHODS.GET,
  })) as AxiosResponse<IOrderTransactionResponse>;

  return data;
};

export const createTransaction = async ({ data, orderId }: IOrderTransactionRequest): Promise<ITransactionDetail> => {
  const { data: transaction } = (await transactionApi({
    data,
    url: `/${orderId}/transactions`,
    method: METHODS.POST,
  })) as AxiosResponse<ITransactionDetail>;

  return transaction;
};

export const getTransaction = async (transactionId: string): Promise<ITransactionDetail> => {
  const { data } = (await transactionApi({
    url: `/transactions/${transactionId}`,
    method: METHODS.GET,
  })) as AxiosResponse<ITransactionDetail>;
  return data;
};

export const getOutgoingTransaction = async (pageParams?: {
  page?: number;
  limit?: number;
  sort?: string;
}): Promise<IIncomingTransactionResponse> => {
  const { data } = (await transactionApi({
    url: '/transactions/outgoing',
    method: METHODS.GET,
    params: {
      limit: pageParams?.limit ? pageParams.limit : 9,
      page: pageParams?.page ? pageParams.page : 1,
      sort: pageParams?.sort ? pageParams.sort : '',
    },
  })) as AxiosResponse<IIncomingTransactionResponse>;
  return data;
};
export const getIncomingTransaction = async (pageParams?: {
  page?: number;
  limit?: number;
  sort?: string;
}): Promise<IIncomingTransactionResponse> => {
  const { data } = (await transactionApi({
    url: '/transactions/incoming',
    method: METHODS.GET,
    params: {
      limit: pageParams?.limit ? pageParams.limit : 9,
      page: pageParams?.page ? pageParams.page : 1,
      sort: pageParams?.sort ? pageParams.sort : '',
    },
  })) as AxiosResponse<IIncomingTransactionResponse>;
  return data;
};

export const createTransactionLog = async ({ data, transactionId, operation }: ICreateTransactionLogRequest) => {
  await transactionApi({
    data,
    url: `/transactions/${transactionId}/${operation}`,
    method: METHODS.POST,
  });
};

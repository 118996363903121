import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { ICartDetail, ICreateCart } from '../../types/interfaces/api/cart.interface';
import { IProductCardFields } from '../../types/interfaces/api/product.interface';
import { Logger } from '../../utils/logger.util';
import { createCart } from '../../api/cart';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import Button from '../Button';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('AddToCart');

function AddToCart({ product, quantity, cardIcon }: { product: IProductCardFields; quantity: number; cardIcon?: boolean }) {
  const queryClient = useQueryClient();

  const { mutate: createCartMutate, isLoading: cartLoading } = useCustomMutation<ICreateCart, ICartDetail>({
    api: createCart,
    success: 'Added to cart',
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyString.CART_DATA).catch(logger.error);
      queryClient.invalidateQueries(QueryKeyString.CART_COUNT).catch(logger.error);
    },
  });

  const onCartClick = () => {
    createCartMutate({
      product: product._id,
      quantity,
    });
  };

  return cardIcon ? (
    <Button
      name="Add to Cart"
      loading={cartLoading}
      buttonWidth="50px"
      buttonPadding="13px 9px"
      borderRadius="8px"
      onClick={onCartClick}
    >
      <FontAwesomeIcon icon={faBasketShopping} />
    </Button>
  ) : (
    <Button loading={cartLoading} onLoadingText="Adding To Cart" onClick={onCartClick}>
      Add to Cart
    </Button>
  );
}

export default AddToCart;

import { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useQuery, useQueryClient } from 'react-query';
import InputField from '../InputField';
import Modal from '../Modal';
import '../../styles/Dashboard/paymentStream.css';
import Button from '../Button';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { getOrderById, updateOrderStatus } from '../../api/order';
import { Logger } from '../../utils/logger.util';
import { ORDER_STATUS } from '../../types/enums/order-status.enum';
import { useZebec } from '../../hooks/useSolana';
import * as toast from '../../utils/toast';
import { usdToSol } from '../../utils/solana.util';
import { BUFFER_SECONDS } from '../../constants';

const StartStreamModal = ({
  orderId,
  hide,
  show,
  refetchOperation,
}: {
  refetchOperation: string;
  show: boolean;
  hide: () => void;
  orderId: string;
}) => {
  const queryClient = useQueryClient();
  const [amount, setAmount] = useState(0);
  const [receiver, setReceiver] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const logger = new Logger('StartStream');
  const { publicKey } = useWallet();
  const { setVisible: setWalletModalVisible } = useWalletModal();
  const { streamStart, startStreamLoading } = useZebec();
  const { mutate: updateTheOrder } = useCustomMutation({
    api: updateOrderStatus,
    success: 'The order has been succesfully accepted.',
    error: 'Error',
    onSuccess: () => {
      queryClient.invalidateQueries([refetchOperation]).catch(logger.error);
    },
  });

  useQuery(['orderDetailById'], async () => getOrderById(orderId), {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (data) => {
      if (!data || !data.renterWallet?.wallet) return;
      setReceiver(data.renterWallet.wallet);
      setAmount(await usdToSol(data.amount));
    },
    onError: (err: Error) => {
      toast.error(err?.message);
    },
  });

  const onAcceptHandler = (id: string, orderStatus: string) => {
    updateTheOrder({
      id,
      status: orderStatus,
    });
  };

  return (
    <>
      <Modal title="Start streaming" hide={hide} isShowing={show}>
        <div className="start-stream-container">
          <div className="input-details">
            <div className="input-field">
              <p className="input-label">Receiver Wallet Address</p>
              <InputField readOnly={true} value={receiver} width="350px" />
            </div>
            <div className="input-field">
              <p className="input-label">Amount in SOL</p>
              <InputField readOnly={true} value={amount} inputType="number" step="0.01" width="350px" />
            </div>
          </div>
          <div className="date-time-container">
            <p className="input-label">Start Time</p>
            <InputField
              placeholder="Start Date"
              inputType="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <InputField
              placeholder="Start Time"
              inputType="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
          <div className="date-time-container">
            <p className="input-label">End Time</p>
            <InputField
              placeholder="End Date"
              inputType="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <InputField
              placeholder="End Time"
              inputType="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
          <Button
            onClick={() => {
              if (!publicKey) {
                toast.error('Wallet not connected');
                setWalletModalVisible(true);
                return;
              }
              streamStart({
                receiver,
                amount,
                startDate,
                startTime,
                endDate,
                endTime,
                orderId,
                bufferSeconds: BUFFER_SECONDS,
              })
                .then(() => {
                  onAcceptHandler(orderId, ORDER_STATUS.PROCESSING);
                })
                .catch(logger.error);
            }}
            loading={startStreamLoading}
            onLoadingText="Stream is being started"
            disabled={!startDate || !startTime || !receiver || !endDate || !endTime}
          >
            Start Streaming
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default StartStreamModal;

import { NativeStream, StreamTransactionResponse } from '@zebec-protocol/stream';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import * as toast from '../utils/toast';

interface IMutateParams<Param, ReturnType> {
  api: (param: Param) => Promise<ReturnType>;
  success?: string;
  error?: string;
  onSuccess?: (data: ReturnType) => void;
  onError?: (err: AxiosError) => void;
}

export function useCustomMutation<Param, ReturnType>({
  api,
  success,
  error,
  onSuccess,
  onError,
}: IMutateParams<Param, ReturnType>) {
  return useMutation(async (param: Param) => api(param), {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
      if (success) toast.success(success);
    },
    onError: (err: AxiosError) => {
      if (onError) onError(err);
      if (error) toast.error(error);
    },
  });
}

interface IZebecMutateParams<Param> {
  api: (native: NativeStream, options: Param) => Promise<StreamTransactionResponse>;
  success?: string;
  error?: string;
  onSuccess?: (data: StreamTransactionResponse) => void;
  onError?: (err: Error) => void;
}

export function useZebecMutation<Param>({ api, success, error, onSuccess, onError }: IZebecMutateParams<Param>) {
  return useMutation(async ({ native, options }: { native: NativeStream; options: Param }) => api(native, options), {
    onSuccess: (data) => {
      if (data.status === 'error') throw new Error(data.message.toString());
      if (onSuccess) onSuccess(data);
      if (success) toast.success(success);
    },
    onError: (err: Error) => {
      if (onError) onError(err);
      if (err.message) toast.error(err.message);
      else if (error) toast.error(error);
    },
  });
}

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useState } from 'react';
import ReceivedOrders from './ReceivedOrders';
import SentOrders from './SentOrders';

function Orders() {
  const [active, setActive] = useState('received');

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Orders - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="content">
        <div className="sectionCont">
          <div className="secHeadCont">
            <h1 className="secHead">Orders</h1>
            <p className="secDesc">A list of offers that you have received and sent.</p>
          </div>
        </div>
        <Tabs className="tabsContainer">
          <TabList className="tabHeader">
            <Tab
              onClick={() => (active === 'received' ? '' : setActive('received'))}
              className={active === 'received' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Received
            </Tab>
            <Tab
              onClick={() => (active === 'sent' ? '' : setActive('sent'))}
              className={active === 'sent' ? 'activeTabTitle tabTitle' : 'tabTitle'}
            >
              Sent
            </Tab>
          </TabList>
          <TabPanel className="tabsContent">
            <ReceivedOrders />
          </TabPanel>
          <TabPanel className="tabsContent">
            <SentOrders />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
export default Orders;

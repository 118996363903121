import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { roundTo2 } from '../../utils/math.utils';
import defaultProductImage from '../../images/defaultProductImage.png';
import { ICartDetail, ICreateCart } from '../../types/interfaces/api/cart.interface';
import DeleteCart from './DeleteCart';
import OrderCart from './OrderCart';
import imageURL from '../../utils/imageUrl';
import Button from '../Button';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { createCart } from '../../api/cart';
import { Logger } from '../../utils/logger.util';
import { QueryKeyString } from '../../types/enums/query-key-string.enum';

const logger = new Logger('CartCard');
export default function CartCard({ product, quantity, _id }: ICartDetail) {
  if (!product) return null;
  const queryClient = useQueryClient();

  const { mutate: createCartMutate, isLoading } = useCustomMutation<ICreateCart, ICartDetail>({
    api: createCart,
    success: 'Cart quantity updated',
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyString.CART_DATA).catch(logger.error);
    },
  });

  const [finalQuantity, setQuantity] = useState(quantity);
  const [totalPrice, setTotalPrice] = useState(roundTo2(product.rentPrice * quantity));

  const increaseQuantity = () => {
    if (product.quantity <= finalQuantity) {
      return;
    }

    setQuantity((prevQuantity) => Math.max(0, prevQuantity + 1));
    setTotalPrice((prevQuantityPrice) => prevQuantityPrice + product.rentPrice);
  };

  const decreaseQuantity = () => {
    if (finalQuantity <= 1) return;
    setQuantity((prevQuantity) => Math.max(0, prevQuantity - 1));
    setTotalPrice((prevQuantityPrice) => prevQuantityPrice - product.rentPrice);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(+e.target.value);
  };

  const onUpdateCart = () => {
    createCartMutate({
      product: product._id,
      quantity: finalQuantity,
    });
  };

  return (
    <>
      <div className="cartContainer">
        <div className="cartItem">
          <div className="cartItemImage">
            <Link to={`/products/${product._id}`}>
              <img
                src={
                  !product.medias || product.medias.length === 0
                    ? defaultProductImage
                    : imageURL(product.medias[0]) ?? defaultProductImage
                }
                alt="Product Image"
              />
            </Link>
          </div>
          <div className="cartItemInfo">
            <p className="cartItemName">{product.name}</p>
            <p className="cartItemDescription">{`Renter : ${product.renter.firstName} ${product.renter.lastName}`}</p>
          </div>
          <div className="cartOrderInfo">
            <div className="cartItemQuantity">
              <p className="cartItemQuantityTitle">Quantity</p>
              <div className="cartItemQuantityValue">
                <div className="quantityCounter">
                  <p className="cartItemQuantityText" onChange={handleChange}>
                    {finalQuantity}
                  </p>
                  <div className="quantityBtns">
                    <div className="valueButton decrease" onClick={decreaseQuantity}>
                      -
                    </div>
                    <div className="valueButton increase" onClick={increaseQuantity}>
                      +
                    </div>
                    <Button onClick={onUpdateCart} loading={isLoading}>
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="cartItemPrice">
              <p className="cartItemPriceTitle">Total Price</p>
              <p className="cartItemPriceText">${roundTo2(totalPrice)}</p>
            </div>
          </div>
          <div className="cartButtonContainer">
            <OrderCart cartId={_id} />
            <DeleteCart cartId={_id} productId={product._id} />
          </div>
        </div>
      </div>
    </>
  );
}

import { useState } from 'react';
import { useZebec } from '../../hooks/useSolana';
import { Logger } from '../../utils/logger.util';
import Button from '../Button';
import InputField from '../InputField';
import Modal from '../Modal';

const logger = new Logger('WithDraw_Transaction');

function WithDrawModal({
  hide,
  show,
  sender,
  pda,
  transactionId,
  amount,
}: {
  hide: () => void;
  show: boolean;
  sender: string;
  pda: string;
  transactionId: string;
  amount: number;
}) {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const { streamWithdraw, withdrawStreamLoading } = useZebec();

  return (
    <>
      <Modal
        isShowing={show}
        hide={() => {
          hide();
        }}
        title="Withdraw"
      >
        <div className="withdrawContent">
          <div className="withdrawAmountInput">
            <div className="inputDesc">Withdraw Amount</div>
            <InputField
              placeholder="Withdraw Amount"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(+e.target.value)}
              width="600px"
              inputType="number"
              step="0.01"
            />
          </div>
          <div className="senderIdInput">
            <div className="inputDesc">Sender</div>
            <InputField placeholder="Sender" value={sender} width="450px" readOnly={true} />
          </div>
        </div>
        <Button
          onClick={() => {
            streamWithdraw({
              pda,
              sender,
              amount: withdrawAmount,
              transactionId,
            }).catch(logger.error);
          }}
          loading={withdrawStreamLoading}
          onLoadingText="Withdrawing SOL to your wallet"
          disabled={!pda || !sender || !withdrawAmount}
        >
          Withdraw money
        </Button>
      </Modal>
    </>
  );
}

export default WithDrawModal;

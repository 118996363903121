// eslint-disable-next-line no-shadow
export enum TRANSACTION_OPERATION {
  PAUSED = 'pause',
  STARTED = 'start',
  STOPPED = 'stop',
  RESUMED = 'resume',
  CANCELLED = 'cancel',
  COMPLETED = 'complete',
  WITHDRAW = 'withdraw',
}

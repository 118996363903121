/* eslint-disable @typescript-eslint/no-misused-promises */
import { ConnectionProvider, useConnection, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { NativeStream } from '@zebec-protocol/stream';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { createDefaultAuthorizationResultCache, SolanaMobileWalletAdapter } from '@solana-mobile/wallet-adapter-mobile';
import {
  CoinbaseWalletAdapter,
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import * as zebec from '../utils/wallet.util';
import * as transactionApi from '../api/transaction';
import { useCustomMutation, useZebecMutation } from './useCustomMutation';
import {
  depositWithdrawNativeParams,
  StartStreamParams,
  StreamParams,
  WithDrawStreamParams,
} from '../types/interfaces/zebec.interface';
import {
  ICreateTransactionLogRequest,
  IOrderTransactionRequest,
  ITransactionDetail,
} from '../types/interfaces/api/transaction.interface';
import { TRANSACTION_OPERATION } from '../types/enums/transaction-operation.enum';
import * as toast from '../utils/toast';

export interface IZebecStartStream {
  receiver: string;
  amount: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  orderId: string;
  bufferSeconds?: number;
}

export interface IZebecStreamAction {
  pda: string;
  receiver: string;
  transactionId: string;
}

export const useZebec = () => {
  const { connection } = useConnection();
  const { publicKey, wallet } = useWallet();
  const [native, setNative] = useState<NativeStream>();
  const [balance, setBalance] = useState(0);
  const [zebecWallet, setZebecWallet] = useState<string | undefined>();

  const getZebecWallet = useMemo(async () => {
    if (!publicKey) return undefined;
    return zebec.getZebecWallet(publicKey);
  }, [publicKey]);

  const getBalance = useCallback(async () => {
    if (!zebecWallet) return;
    const zebecBalance = await zebec.getZebecBalance(zebecWallet, connection);
    setBalance(zebecBalance / 1e9);
  }, [connection, zebecWallet]);

  useEffect(() => {
    setNative(zebec.initNativeStream(wallet?.adapter, connection.rpcEndpoint));
  }, [connection, publicKey, wallet]);

  useEffect(() => {
    (async () => {
      setZebecWallet(await getZebecWallet);
    })().catch(() => {
      toast.error('Error getting Zebec wallet');
    });
  }, [getZebecWallet]);

  useEffect(() => {
    (async () => {
      await getBalance();
    })().catch(() => {
      toast.error('Error getting Zebec balance');
    });
  }, [zebecWallet, getBalance]);

  const { mutateAsync: createTransaction, isLoading: createTransactionLoading } = useCustomMutation<
    IOrderTransactionRequest,
    ITransactionDetail
  >({
    api: transactionApi.createTransaction,
    success: 'Transaction created',
    error: 'Transaction creation failed',
  });

  const { mutateAsync: createTransactionLog, isLoading: createTransactionLogLoading } = useCustomMutation<
    ICreateTransactionLogRequest,
    void
  >({
    api: transactionApi.createTransactionLog,
    success: 'Transaction log created',
    error: 'Transaction log creation failed',
  });

  const { mutateAsync: startStream, isLoading: startStreamLoading } = useZebecMutation<StartStreamParams>({
    api: zebec.startStream,
    success: 'Stream started',
    error: 'Stream start failed',
  });

  const { mutateAsync: pauseStream, isLoading: pauseStreamLoading } = useZebecMutation<StreamParams>({
    api: zebec.pauseStream,
    success: 'Stream paused',
    error: 'Stream pause failed',
  });

  const { mutateAsync: resumeStream, isLoading: resumeStreamLoading } = useZebecMutation<StreamParams>({
    api: zebec.resumeStream,
    success: 'Stream resumed',
    error: 'Stream resume failed',
  });

  const { mutateAsync: stopStream, isLoading: stopStreamLoading } = useZebecMutation<StreamParams>({
    api: zebec.stopStream,
    success: 'Stream stopped',
    error: 'Stream stop failed',
  });

  const { mutateAsync: withdrawStream, isLoading: withdrawStreamLoading } = useZebecMutation<WithDrawStreamParams>({
    api: zebec.withdrawStream,
    success: 'Stream withdrawn',
    error: 'Stream withdrawAmount failed',
  });

  const { mutateAsync: depositToZebecWallet, isLoading: walletDepositLoading } =
    useZebecMutation<depositWithdrawNativeParams>({
      api: zebec.depositToZebecWallet,
      success: 'SOL deposited',
      error: 'SOL deposit failed',
      onSuccess: async () => {
        await getBalance();
      },
    });

  const { mutateAsync: withdrawFromZebecWallet, isLoading: walletWithdrawLoading } =
    useZebecMutation<depositWithdrawNativeParams>({
      api: zebec.withdrawFromZebecWallet,
      success: 'SOL withdrawn from wallet',
      error: 'SOL withdraw failed',
      onSuccess: async () => {
        await getBalance();
      },
    });

  const walletDeposit = useCallback(
    async (depositZebecAmount: number): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender: publicKey.toBase58(),
        amount: +depositZebecAmount,
      };

      await depositToZebecWallet({ native, options });
    },
    [native, publicKey]
  );

  const walletWithdraw = useCallback(
    async (withdrawZebecWithdraw: number): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender: publicKey.toBase58(),
        amount: +withdrawZebecWithdraw,
      };

      await withdrawFromZebecWallet({ native, options });
    },
    [native, publicKey]
  );

  const streamWithdraw = useCallback(
    async ({
      pda,
      sender,
      amount: withdrawAmount,
      transactionId,
    }: {
      pda: string;
      sender: string;
      amount: number;
      transactionId: string;
    }): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender,
        receiver: publicKey.toBase58(),
        pda,
        amount: +withdrawAmount,
      };

      const streamData = await withdrawStream({ native, options });
      await createTransactionLog({
        data: {
          transactionHash: streamData.data.transactionHash,
        },
        transactionId,
        operation: TRANSACTION_OPERATION.WITHDRAW,
      });
    },
    [native, publicKey]
  );

  const streamStart = useCallback(
    async ({
      receiver: streamReceiver,
      startDate,
      endDate,
      startTime,
      endTime,
      amount: streamAmount,
      orderId,
      bufferSeconds = 15,
    }: IZebecStartStream) => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      if (!startDate || !endDate) throw new Error('Start and end date are required');

      const streamStartTime = Math.floor(
        Date.parse(new Date(`${startDate}T${startTime}:${bufferSeconds}`).toString()) / 1000
      );
      const streamEndTime = Math.floor(Date.parse(new Date(`${endDate}T${endTime}:${bufferSeconds}`).toString()) / 1000);

      const options = {
        sender: publicKey.toBase58(),
        receiver: streamReceiver,
        amount: +streamAmount,
        start_time: streamStartTime,
        end_time: streamEndTime,
      };

      const streamData = await startStream({ native, options });
      if (!streamData?.data?.pda) throw new Error('PDA not found');

      const transaction = await createTransaction({
        data: {
          pda: streamData.data.pda,
          transactionHash: streamData.data.transactionHash,
          sender: publicKey.toBase58(),
          receiver: streamReceiver,
          amount: +streamAmount,
          startTime: new Date(streamStartTime * 1000).toISOString(),
          endTime: new Date(streamEndTime * 1000).toISOString(),
        },
        orderId,
      });

      return {
        pda: streamData.data.pda,
        transaction,
      };
    },
    [native, publicKey]
  );

  const streamPause = useCallback(
    async ({ pda, receiver: streamReceiver, transactionId }: IZebecStreamAction): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender: publicKey.toBase58(),
        receiver: streamReceiver,
        pda,
      };

      const streamData = await pauseStream({ native, options });
      const transaction = await createTransactionLog({
        data: {
          transactionHash: streamData.data.transactionHash,
        },
        transactionId,
        operation: TRANSACTION_OPERATION.PAUSED,
      });

      return transaction;
    },
    [native, publicKey]
  );

  const streamResume = useCallback(
    async ({ pda, receiver: streamReceiver, transactionId }: IZebecStreamAction): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender: publicKey.toBase58(),
        receiver: streamReceiver,
        pda,
      };

      const streamData = await resumeStream({ native, options });
      await createTransactionLog({
        data: {
          transactionHash: streamData.data.transactionHash,
        },
        transactionId,
        operation: TRANSACTION_OPERATION.RESUMED,
      });
    },
    [native, publicKey]
  );

  const streamStop = useCallback(
    async ({ pda, receiver: streamReceiver, transactionId }: IZebecStreamAction): Promise<void> => {
      if (!native) throw new Error('Native stream is not initialized');
      if (!publicKey) throw new WalletNotConnectedError();

      const options = {
        sender: publicKey.toBase58(),
        receiver: streamReceiver,
        pda,
      };

      const streamData = await stopStream({ native, options });
      await createTransactionLog({
        data: {
          transactionHash: streamData.data.transactionHash,
        },
        transactionId,
        operation: TRANSACTION_OPERATION.STOPPED,
      });
    },
    [native, publicKey]
  );

  return {
    streamStart,
    startStreamLoading,
    streamPause,
    pauseStreamLoading,
    streamResume,
    resumeStreamLoading,
    streamStop,
    stopStreamLoading,
    streamWithdraw,
    withdrawStreamLoading,
    walletDeposit,
    walletDepositLoading,
    walletWithdraw,
    walletWithdrawLoading,
    zebecWallet,
    zebecBalance: balance,
  };
};

export const useSolana = () => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new SolanaMobileWalletAdapter({
        appIdentity: { name: 'Solana Wallet Adapter App' },
        authorizationResultCache: createDefaultAuthorizationResultCache(),
      }),
      new CoinbaseWalletAdapter(),
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );

  const SolanaProvider = ({ children }: PropsWithChildren) => (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );

  return {
    SolanaProvider,
  };
};

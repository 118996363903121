/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import '../../styles/auth.css';
import '../../styles/otpField.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import Button from '../../components/Button';
import { Logger } from '../../utils/logger.util';

const logger = new Logger('VerifyEmail');

export default function VerifyEmail() {
  const [email, setEmail] = useState('test@gmail.com');
  const [result, setResult] = useState('');
  const handleOnChange = (res: string) => {
    setResult(res);
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      email,
      token: result,
    };
    logger.log(data);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Verify Email - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="authWrapper">
        <div className="authContainer">
          <div className="authFormHead">
            <h1 className="authHeader">Verify your email address.</h1>
            <p className="authSubHeader">Fill up the OTP code to start accessing the website,</p>
          </div>
          <form onSubmit={handleOnSubmit}>
            <div className="otpField">
              <AuthCode allowedCharacters="numeric" onChange={handleOnChange} />
            </div>
            <Button>Verify Email</Button>
            <div className="authSubText">
              <p>Didn&#8217;t receive the code?</p>
              <Link to="/">
                <span>Send again</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import { ILoginResponse, IUserResponse } from '../types/interfaces/api/auth.interface';
import createApi from '../utils/axios';

const userApi = createApi('/auth');

export interface IRegisterRequest {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ILoginRequest {
  username: string;
  password: string;
}

export const register = async (registerBody: IRegisterRequest) => {
  await userApi({
    url: '/register',
    method: METHODS.POST,
    data: registerBody,
  });
};

export const login = async (loginBody: ILoginRequest): Promise<ILoginResponse> => {
  const { data } = (await userApi({
    url: '/login',
    method: METHODS.POST,
    data: loginBody,
  })) as AxiosResponse<ILoginResponse>;

  return data;
};

export const getMe = async (): Promise<IUserResponse> => {
  const { data } = (await userApi({
    url: '/me',
    method: METHODS.GET,
  })) as AxiosResponse<IUserResponse>;

  return data;
};

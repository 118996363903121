/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import '../../styles/auth.css';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import useFormInput from '../../hooks/useFormInput';
import { Logger } from '../../utils/logger.util';

type inputBoxDetail = {
  id: number;
  inputType: string;
  width: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  display?: string;
  marginTop?: string;
};

const inputBoxDetails: Array<inputBoxDetail> = [
  {
    id: 1,
    inputType: 'email',
    placeholder: 'Email Address',
    width: '450px',
    name: 'email',
    isRequired: true,
  },
];

const logger = new Logger('ForgotPassword');

export default function ForgotPassword() {
  const input = useFormInput([{ name: 'email' }]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      email: input.email.value,
    };
    logger.log(data);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Forgot Password - Rent Store</title>
        </Helmet>
      </HelmetProvider>
      <div className="authWrapper">
        <div className="authContainer">
          <div className="authFormHead">
            <h1 className="authHeader">Forgot your password?</h1>
            <p className="authSubHeader">FIll up the below form to reset your password.</p>
          </div>
          <form onSubmit={handleSubmit}>
            {inputBoxDetails.map((item) => (
              <InputField
                key={item.id}
                {...item}
                value={input[`${item.name}`].value ?? ''}
                onChange={input[`${item.name}`].handleChange}
              />
            ))}
            <Button>Send OTP</Button>
            <div className="authSubText">
              <p>Didn&#8217;t receive the code?</p>
              <Link to="/">
                <span>Send again</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { solToUsd } from '../../../utils/solana.util';
import { Logger } from '../../../utils/logger.util';
import { useZebec } from '../../../hooks/useSolana';
import Button from '../../../components/Button';
import InputField from '../../../components/InputField';
import TransactionLayout from '../../../components/Transactions/TransactionsLayout';

import '../../../styles/Dashboard/zebecWallet.css';
import WalletNotConnected from '../../../components/WalletNotConnected';

const logger = new Logger('Zebec_Wallet');

function ZebecWallet() {
  const { publicKey } = useWallet();
  const [depositAmount, setDepositAmount] = useState(0);
  const [$Balance, set$Balance] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const { zebecBalance, walletDeposit, walletWithdraw } = useZebec();
  useEffect(() => {
    solToUsd(zebecBalance)
      .then((price) => {
        set$Balance(price);
      })
      .catch(logger.error);
  });

  return (
    <TransactionLayout
      heading="Access your Zebec Wallet."
      description="Deposit or withdraw money to or from your Zebec Wallet."
    >
      {publicKey ? (
        <>
          <div className="zebecWalletTitle">ZEBEC WALLET BALANCE</div>
          <div className="zebecBalance">
            <div className="zebecBalanceSOL">
              {zebecBalance}&nbsp;<span>SOL</span>
            </div>
            <div className="zebecBalanceDollar">${$Balance || 0}</div>
          </div>
          <div className="zebecTransfer">
            <div className="zebecDeposit">
              <div className="depositTitle">Deposit Money</div>
              <InputField
                placeholder="deposit amount"
                value={depositAmount}
                onChange={(e) => {
                  if (e.target.value === '') setDepositAmount(0);
                  if (+e.target.value) setDepositAmount(+e.target.value);
                }}
                width="408px"
                inputType="number"
                step="0.01"
              />
              <span className="solSpan">SOL</span>
              <Button
                onClick={() => {
                  walletDeposit(depositAmount).catch(logger.error);
                }}
                disabled={!publicKey || !depositAmount}
              >
                Deposit
              </Button>
            </div>
            <div className="zebecWithdraw">
              <div className="withdrawTitle">Withdraw Money</div>
              <InputField
                placeholder="withdraw amount"
                value={withdrawAmount}
                onChange={(e) => {
                  if (e.target.value === '') setWithdrawAmount(0);
                  if (+e.target.value) setWithdrawAmount(+e.target.value);
                }}
                width="408px"
                inputType="number"
                step="0.01"
              />
              <span className="solSpan">SOL</span>
              <Button
                onClick={() => {
                  walletWithdraw(withdrawAmount).catch(logger.error);
                }}
                disabled={!publicKey || !withdrawAmount}
              >
                Withdraw
              </Button>
            </div>
          </div>
        </>
      ) : (
        <WalletNotConnected />
      )}
    </TransactionLayout>
  );
}

export default ZebecWallet;

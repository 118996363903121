import { AxiosResponse } from 'axios';
import { METHODS } from '../enums/axios.enum';
import { IMediaDetail, IMediaResponse } from '../types/interfaces/api/media.interface';
import createApi from '../utils/axios';

const mediaApi = createApi('/medias');

export const uploadMedia = async (mediaBody: FileList) => {
  const { data } = (await mediaApi({
    url: '/',
    method: METHODS.POST,
    data: mediaBody,
  })) as AxiosResponse<IMediaDetail>;

  return data;
};

export const getMedia = async ({ page = 1, limit = 4 }): Promise<IMediaResponse> => {
  const { data } = (await mediaApi({
    method: METHODS.GET,
    url: '/',
    params: { limit, page },
  })) as AxiosResponse<IMediaResponse>;
  return data;
};
